import React from 'react';
import QuickShopCharts from './components/dashboard/QuickShopCharts';
import QuickShopTable from './components/dashboard/QuickShopTables';
import StatsQuickShop from './components/dashboard/StatsQuickShop';
import QuickShopTopCards from './components/dashboard/topCardsQuickShop';

export const API_date_format = 'YYYY/MM/DD';
type Props = {
    siteId: any;
    startDate: any;
    endDate: any;
    compaison?: any;
    compare?: any;
    compare_start_date?: any;
    compare_end_date?: any;
    revenueType?: any;
};

const QuickShopDashboard: React.FC<Props> = ({ siteId, startDate, endDate, compaison, compare, compare_start_date, compare_end_date, revenueType }) => {

    return (
        <div className="dashboard">
            <QuickShopTopCards
                startDate={startDate}
                endDate={endDate}
                revenueType={revenueType}
                comparison={compaison}
                compare={compare}
                compare_start_date={compare ? compare_start_date : ''}
                compare_end_date={compare ? compare_end_date : ''}
                siteId={siteId === 0 ? null : siteId}
            />

            <QuickShopTable startDate={startDate} endDate={endDate} revenueType={revenueType} compare={compaison} compare_start_date={compare_start_date} compare_end_date={compare_end_date} siteId={siteId === 0 ? null : siteId} />

            <StatsQuickShop startDate={startDate} endDate={endDate} revenueType={revenueType} siteId={siteId === 0 ? null : siteId} />

            <QuickShopCharts startDate={startDate} endDate={endDate} revenueType={revenueType} compare={compaison} compare_start_date={compaison === false ? '' : compare_start_date} compare_end_date={compaison === false ? '' : compare_end_date} />
        </div>
    );
};

export default QuickShopDashboard;
