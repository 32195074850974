import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store/RootReducer';
import AdOptDashboardAction from '../../../../AdOptimization/redux/actions';
import { commaSeperator } from '../../../../../../../utils/Validation';
import OverviewLineAreaChart from '../../SiteDetails/Charts/OverviewLineAreaChart';
import { Checkbox } from 'antd';

export const API_date_format = 'YYYY/MM/DD';
type Props = {
    siteId: string;
    startDate: string;
    endDate: string;
    revenueType: string;
    compaison?: any;
    compare: boolean;
    compare_start_date: string;
    compare_end_date: string;
    adServer?: string;
    applyDate?:string;
};

const RevenueOverview: React.FC<Props> = ({ siteId, startDate, endDate, revenueType, compaison, compare, compare_start_date, compare_end_date, applyDate, adServer }) => {
    const dispatch = useDispatch();
    const [modifGraphData,setModifGraphData] = useState<any>([]);
    const [compareRevs, setCompareRevs] = useState({
        adopt: true,
        subs: true,
        crowd: true,
        total:true
    })

    const overviewGraph = useSelector((state: RootState) => state.adOptDashboard.OverviewGraph);
    const overviewGraphLoader = useSelector((state: RootState) => state.adOptDashboard.OverviewGraphLoader);
  
    useEffect(() => {
        if (startDate && endDate) {
            dispatch(
                AdOptDashboardAction.fetchOverviewGraphData(
                    {
                        end_date: endDate,
                        start_date: startDate,
                        revenue: revenueType,
                        site_id: siteId,
                        ad_server: adServer
                    }
                ),
            );
        }
    }, [dispatch, startDate, endDate, siteId]);

    useEffect(()=>{
        let dummData:any = overviewGraph;
        //total_rev
        dummData?.map((item:any)=>{
           item["total_rev"] = parseFloat((item?.ad_revenue + item?.crow_revenue + item?.subs_revenue).toFixed(2))
        })
        setModifGraphData(dummData);

    },[overviewGraph])

    return (
        <>
            <div className="py-8 px-4">
                <div className="flex ">
                <div className="flex justify-center items-center font-bold text-[28px] lg:mr-12">Revenue Overview</div>
                    <div className='flex justify-center items-center lg:mr-12'>
                        <Checkbox className="customCheckBox2" checked={compareRevs?.total} onChange={(e) => { setCompareRevs({ ...compareRevs, total: e.target.checked }) }}></Checkbox>
                        <div className='font-[Roboto] font-[500] text-[16px] ml-1'>Total Revenue</div>
                    </div>
                    <div className='flex justify-center items-center lg:mr-12'>
                        <Checkbox className="customCheckBox2" checked={compareRevs?.adopt} onChange={(e) => { setCompareRevs({ ...compareRevs, adopt: e.target.checked }) }}></Checkbox>
                        <div className='font-[Roboto] font-[500] text-[16px] ml-1'>Ad Optimization</div>
                    </div>
                    <div className='flex justify-center items-center lg:mr-12'>
                        <Checkbox className="customCheckBox2" checked={compareRevs?.subs} onChange={(e) => { setCompareRevs({ ...compareRevs, subs: e.target.checked }) }}></Checkbox>
                        <div className='font-[Roboto] font-[500] text-[16px] ml-1'>Subscriptions </div>
                    </div>
                    <div className='flex justify-center items-center lg:mr-12 '>
                        <Checkbox className="customCheckBox2" checked={compareRevs?.crowd} onChange={(e) => { setCompareRevs({ ...compareRevs, crowd: e.target.checked }) }}></Checkbox>
                        <div className='font-[Roboto] font-[500] text-[16px] ml-1'>Crowd Funding</div>
                    </div>
                </div>
                <OverviewLineAreaChart
                    suffix={'$'}
                    prefix={'$'}
                    compareRevs={compareRevs}
                    title=""
                    value={`$${commaSeperator(parseFloat(String(overviewGraph ?? 0)).toFixed(2))}`}
                    data={modifGraphData}
                    loading={overviewGraphLoader || false}
                    />
            </div>
        </>
    );
};

export default RevenueOverview;
