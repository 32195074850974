import React, { useEffect, useState } from 'react'
import { DateTable, FavoriteOrRecent } from '../../../AdOptimization/redux/types';
import moment from 'moment';
import { BiArrowBack } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { HEADERMENU_PATH } from '../../../../../../routes/RoutesURL';
import PTable from '../../../../../common/Table';
import { RootState } from '../../../../../../store/RootReducer';
import { GoPrimitiveDot } from 'react-icons/go';
import { commaSeperator } from '../../../../../../utils/Validation';
import SubsDashboardAction from '../../../../dashboard/Subscriptions/redux/actions';
import ModalLogs from './ModalLogs';

type Props = {
     siteId: any;
     startDate: any;
     endDate: any;
     name: any
};
const SubsUnsubsDetail: React.FC<Props> = ({ siteId, startDate, endDate, name }) => {
     const navigate = useNavigate();
     const dispatch = useDispatch();
     const [logModalKeys, setLogModalKeys] = useState<FavoriteOrRecent | undefined>(undefined);
     const [open2, setOpen2] = useState(false);

     const newSubscribersListData = useSelector((state: RootState) => state.subsDashboard?.subscriptionNewSubscribersTable);
     const newSubscribersListLoader = useSelector((state: RootState) => state.subsDashboard.subscriptionNewSubscribersTableLoader);

     const cancelledSubscribersListData = useSelector((state: RootState) => state.subsDashboard.subscriptionCancelledSubscribersTable);
     const cancelledSubscribersListLoader = useSelector((state: RootState) => state.subsDashboard.subscriptionCancelledSubscribersTableLoader);

     console.log(cancelledSubscribersListData);
     const onClickBack = () => {
          navigate(`${HEADERMENU_PATH.newDashboard}${HEADERMENU_PATH.subscriptions}`);
     };

     useEffect(() => {
          if (name === 'newSubs' && startDate && endDate) {
               dispatch(
                    SubsDashboardAction.fetchNewSubscriberList({
                         start_date: startDate,
                         end_date: endDate,
                         site_id: siteId
                    })
               );
          } else if (name === 'unSubs' && startDate && endDate) {
               dispatch(
                    SubsDashboardAction.fetchCancelledSubscriberList({
                         start_date: startDate,
                         end_date: endDate,
                         site_id: siteId
                    })
               );
          }
     }, [startDate, endDate, siteId, dispatch]);

     const columns = [
          {
               dataIndex: 'site_name',
               title: 'Site',
               width: 250,
               fixed: 'left',
               render: (text: any, row: FavoriteOrRecent, index: number) => {
                    return <div className="flex items-center justify-start">{text}</div>;
               },
               sorter: (a: any, b: any) => String(a.site_name).localeCompare(String(b.site_name)),
          },
          {
               dataIndex: 'name',
               title: 'Name',
               render: (text: any, row: FavoriteOrRecent) => (
                    <div
                         className="flex items-center justify-start gap-2 text-green-600 underline"
                         onClick={() => {
                              //  setOpen(true);
                              //  setModalKeys(row);
                         }}
                    >
                         <span>{row?.status === 0 ? <GoPrimitiveDot size={24} color="red" /> : <GoPrimitiveDot size={24} color="green" />}</span>
                         <span>{String(text || ' ')}</span>
                    </div>
               ),
               sorter: (a: any, b: any) => String(a.name).localeCompare(String(b.name)),
          },
          {
               dataIndex: 'email',
               title: 'Email',
               render: (text: any, row: FavoriteOrRecent) => (
                    <div
                         className="flex items-center justify-start gap-2 text-green-600 underline"
                         onClick={() => {
                              //  setOpen(true);
                              //  setModalKeys(row);
                         }}
                    >
                         <span>{row?.status === 0 ? <GoPrimitiveDot size={24} color="red" /> : <GoPrimitiveDot size={24} color="green" />}</span>
                         <span>{String(text || ' ')}</span>
                    </div>
               ),
               sorter: (a: any, b: any) => String(a.email).localeCompare(String(b.email)),
          },
          {
               dataIndex: 'date',
               title: 'Signed Up',
               width: 250,
               render: (text: any, row: FavoriteOrRecent) => (
                    <div className="flex items-center justify-center gap-2">
                         <span>{text}</span>
                    </div>
               ),
               sorter: (a: DateTable, b: DateTable) => moment(a.date).unix() - moment(b.date).unix(),
          },
          {
               dataIndex: 'amount',
               title: 'Amount',
               width: 150,
               render: (text: any, row: FavoriteOrRecent) => {
                    return (
                         <div className="flex items-center justify-end gap-2">
                              <span>{`$${parseFloat(commaSeperator(text)).toFixed(2)}`}</span>
                         </div>
                    );
               },
               sorter: (a: any, b: any) => parseFloat(a.amount) - parseFloat(b.amount),
          },
          {
               dataIndex: 'plan_type',
               title: 'Plan Type',
               width: 150,
               render: (text: any, row: FavoriteOrRecent) => (
                    <div className="flex items-center justify-center gap-2">
                         <span>{text}</span>
                    </div>
               ),
               sorter: (a: any, b: any) => String(a.plan_type).localeCompare(String(b.plan_type)),
          },
          {
               dataIndex: '',
               title: 'Logs',
               width: 100,
               render: (text: any, row: FavoriteOrRecent) => (
                    <div
                         className="flex items-center justify-center gap-2 text-green-600 underline "
                         onClick={() => {
                              setOpen2(true);
                              setLogModalKeys(row);
                         }}
                    >
                         <span>{'Logs'}</span>
                    </div>
               ),
          },
     ];
     return (
          <div>
               <div className="relative flex justify-between items-center pb-4">
                    <div className="flex items-start mt-4">
                         <div onClick={onClickBack} className="bg-[#B4D0C133] cursor-pointer h-10 flex items-center px-4 rounded-lg mr-2 mt-1">
                              <BiArrowBack className="mr-2" />
                              <span>Back</span>
                         </div>
                    </div>

               </div>

               <PTable className="dashboard_table"
                    columns={columns}
                    data={name === "newSubs" ? newSubscribersListData : name === "unSubs" ? cancelledSubscribersListData : []}
                    loading={name === "newSubs" ? newSubscribersListLoader : name === "unSubs" ? cancelledSubscribersListLoader : false}
                    pagination={{ isShow: true }} rowKey={(d: any) => `${Math.random()}`} />
               <ModalLogs open2={open2} setOpen2={setOpen2} logModalData={logModalKeys} />
          </div>
     )
}

export default SubsUnsubsDetail