import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PTable from '../../../../../common/Table';
import PButton from '../../../../../common/Button';
import { RootState } from '../../../../../../store/RootReducer';
import { useDispatch, useSelector } from 'react-redux';
import AdOptDashboardAction from '../../redux/actions';
import { commaSeperator } from '../../../../../../utils/Validation';
import { DemandChannel } from '../../redux/types';
import DeltaBox from '../../../../../common/DeltaBox';
import { HEADERMENU_PATH } from '../../../../../../routes/RoutesURL';
import { useNavigate } from 'react-router-dom';
import Apis from '../../../../../../api';
import download from 'downloadjs';
import MessageActions from '../../../../../message/redux/actions';

type Props = {
    siteId?: string;
    startDate?: string;
    endDate?: string;
    revenueType?: string;
    compare?: boolean;
    compare_start_date?: string;
    compare_end_date?: string;
    ad_server?: string;
    comparison?: boolean;
    time_interval?:string;
    adServer?: boolean;
    applyDate?:string;
};
const DemandChannelList: React.FC<Props> = ({ siteId, startDate, endDate, revenueType, compare, compare_start_date, compare_end_date, ad_server, comparison, time_interval, applyDate, adServer }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loading = useSelector((state: RootState) => state.adOptDashboard.demandChannelLoading);
    const data = useSelector((state: RootState) => state.adOptDashboard.demandChannel);
    const [fileLoading, setLoading] = useState(false);
    const [activeTab] = useState('all');

    useEffect(() => {
        if (startDate && endDate && ad_server) {
            dispatch(
                AdOptDashboardAction.fetchDemandChannel({
                    start_date: startDate,
                    end_date: endDate,
                    revenue: revenueType,
                    compare: compare,
                    compare_start_date: compare_start_date,
                    compare_end_date: compare_end_date,
                    ad_server: ad_server,
                    site_id: siteId,
                }),
            );
        }
    }, [startDate, endDate, dispatch, revenueType, compare, compare_start_date, compare_end_date, ad_server, siteId, applyDate, adServer]);

    const columns = [
        {
            dataIndex: 'network_name',
            title: 'Name',
            render: (text: string) => <span className="underline">{text}</span>,
            sorter: (a: DemandChannel, b: DemandChannel) => String(a.network_name).localeCompare(String(b.network_name)),
        },
        {
            dataIndex: 'total_impressions',
            title: 'Impressions Share',
            render: (text?: string, row?: DemandChannel) => {
                return (
                    <div className="flex justify-end">
                        <span>{text ? `${commaSeperator(text)}` : ''}</span>
                        {comparison && <DeltaBox row={row?.impressions_percentage} />}
                    </div>
                );
            },
            sorter: (a: DemandChannel, b: DemandChannel) => parseInt(a.total_impressions) - parseInt(b.total_impressions),
        },
        {
            dataIndex: 'total_revenue',
            title: 'Revenue',
            render: (text?: string, row?: DemandChannel) => (
                <div className="flex justify-end">
                    <span>{text ? `$${commaSeperator(parseFloat(text).toFixed(2))}` : ''}</span>
                    {comparison && <DeltaBox row={row?.revenue_percentage} />}
                </div>
            ),
            sorter: (a: DemandChannel, b: DemandChannel) => parseFloat(a.total_revenue || '0') - parseFloat(b.total_revenue || '0'),
        },
        {
            dataIndex: 'total_cpm',
            title: 'CPM',
            render: (text?: string, row?: DemandChannel) => (
                <div className="flex justify-end">
                    <span>{text ? `$${commaSeperator(parseFloat(text).toFixed(2))}` : ''}</span>
                    {comparison && <DeltaBox row={row?.total_cpm_percentage} />}
                </div>
            ),
            sorter: (a: DemandChannel, b: DemandChannel) => parseFloat(a.total_cpm || '0') - parseFloat(b.total_cpm || '0'),
        },
    ];

    // useMemo(() => {
    //     data?.sort((a: any, b: any) => {
    //         return String(a.network_name).localeCompare(String(b.network_name));
    //     });
    // }, [data]);

    const handleExport = () => {
        if (startDate && endDate) {
            const interval = applyDate === 'last1Day' ? 'last1days' : applyDate === 'last7Day' ? 'last7days' : applyDate === 'last30Day' ? 'last30days' : 'customDates';
            const data = {
                start_date: `${startDate}`,
                end_date: `${endDate}`,
                table_type: `${activeTab}`,
                search_site: '',
                compare: compare,
                revenue: `${revenueType}`,
                time_interval: `${interval}`,
                compare_start_date: `${compare_start_date}`,
                compare_end_date: `${compare_end_date}`,
                ad_server: adServer ? 'ON' : 'OFF',
                site_id: siteId,
            };
            setLoading(true);

            Apis.fetchDemandPartnersExportTable(data)
                .then(({ data }) => {
                    download(data, `${moment().format('YYYY/MM/DD')}_${activeTab}.xlsx`);
                })
                .catch((err) => {
                    dispatch(MessageActions.showMessage({ text: String('Something went wrong'), error: true }));
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };
    
    return (
        <div className="pt-6 pb-4">
            <p className="roboto-medium leading-7 text-2xl">Demand Partners</p>
            <div className="w-[100%] relative flex flex-row-reverse -mt-8">
                <PButton loading={fileLoading} className="" title={'Export'} onClick={handleExport} />
            </div>
            <div className="pt-4">
                <PTable columns={columns} data={data} pagination={{isShow:true }} className="dashboard_table" rowKey={(d) => `${d.network_id}_${Math.random()}`} loading={loading} onRowClick={(item)=> navigate(`${HEADERMENU_PATH.newDashboard}${HEADERMENU_PATH.adOptimization}`,{state:{networkId:item.network_id,network_name:item.network_name, start_date: startDate, end_date: endDate, time_interval: time_interval}}) } />
            </div>
        </div>
    );
};

export default DemandChannelList;
