import React from 'react'
type Props = {
     color: string;
}
const AdminSvgFile: React.FC<Props> = ({ color }) => {
     return (
          <div className='mr-[10px] h-[36px] w-fit flex justify-center items-center'>
               <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.3861 12.8403V8.54585L18.7901 7.92779C18.673 7.58514 18.5338 7.24938 18.3735 6.92355L19.7725 4.65025L16.7358 1.61361L14.4625 3.01259C14.1368 2.85239 13.801 2.71309 13.4583 2.59599L12.8403 0H8.5459L7.92779 2.59599C7.58514 2.71309 7.24938 2.85239 6.9236 3.01259L4.6503 1.61361L1.61361 4.65025L3.01255 6.92355C2.85234 7.24938 2.71305 7.58514 2.59594 7.92779L0 8.54581V12.8402L2.59599 13.4583C2.71309 13.801 2.85239 14.1368 3.01259 14.4626L1.60705 16.7466L3.71259 18.7633C3.48951 19.4319 3.37426 20.135 3.37426 20.8488V24H18.1069V20.8488C18.1069 20.1594 17.9989 19.4801 17.7905 18.8321L19.7622 16.7191L18.3736 14.4626C18.5338 14.1368 18.6731 13.801 18.7902 13.4584L21.3861 12.8403ZM16.6812 22.5743H4.8V20.8488C4.8 19.86 5.08173 18.8971 5.61476 18.0643C6.56897 16.5733 8.19545 15.6832 9.96561 15.6832H11.5156C13.2857 15.6832 14.9122 16.5733 15.8664 18.0642C16.3995 18.8971 16.6812 19.86 16.6812 20.8488V22.5743ZM10.7406 14.198C8.92263 14.198 7.44356 12.719 7.44356 10.901C7.44356 9.08303 8.92263 7.60396 10.7406 7.60396C12.5586 7.60396 14.0376 9.08303 14.0376 10.901C14.0376 12.719 12.5586 14.198 10.7406 14.198ZM17.9784 16.5408L17.1495 17.4292C17.1224 17.3846 17.0955 17.3397 17.0673 17.2957C16.2496 16.0181 15.0448 15.087 13.6577 14.6127C14.7565 13.7472 15.4633 12.4051 15.4633 10.901C15.4633 8.29687 13.3447 6.17822 10.7405 6.17822C8.13643 6.17822 6.01777 8.29687 6.01777 10.901C6.01777 12.4051 6.72456 13.7472 7.82343 14.6127C6.43632 15.087 5.23148 16.0181 4.41381 17.2957C4.39186 17.33 4.37118 17.365 4.34989 17.3996L3.42459 16.5133L4.65733 14.5101L3.7249 12.2616L1.42574 11.7141V9.67195L3.72494 9.12451L4.65738 6.87602L3.41803 4.86211L4.86207 3.41808L6.87602 4.65743L9.12451 3.72499L9.672 1.42574H11.7142L12.2616 3.72494L14.5101 4.65743L16.524 3.41808L17.9681 4.86211L16.7287 6.87602L17.6611 9.12451L19.9603 9.67195V11.7141L17.6611 12.2616L16.7287 14.5101L17.9784 16.5408Z" fill={color} />
               </svg>
          </div>
     )
}

export default AdminSvgFile