import { Checkbox } from 'antd'
import React, { useState } from 'react'
import AdOptRevImpLineGraph from './AdOptRevImpLineGraph'
type Props ={
     data?:any;
     loader?:boolean;
}
const AdOptRevImp:React.FC<Props>=({data,loader})=> {
     const [compareRevImp, setCompareRevImp] = useState({
          rev: true,
          imp: true,
      })
     return (
          <>
               <div className="py-8 px-4">
                    <div className="flex ">
                         <div className="flex justify-center items-center font-bold text-[28px] lg:mr-12">Revenue Vs Impressions</div>
                         <div className='flex justify-center items-center lg:mr-12'>
                              <Checkbox className="customCheckBox2" checked={compareRevImp?.rev} onChange={(e) => { setCompareRevImp({ ...compareRevImp, rev: e.target.checked }) }}></Checkbox>
                              <div className='font-[Roboto] font-[500] text-[16px] ml-1'>Revenue</div>
                         </div>
                         <div className='flex justify-center items-center lg:mr-12'>
                              <Checkbox className="customCheckBox2" checked={compareRevImp?.imp} onChange={(e) => { setCompareRevImp({ ...compareRevImp, imp: e.target.checked }) }}></Checkbox>
                              <div className='font-[Roboto] font-[500] text-[16px] ml-1'>Impressions Share</div>
                         </div>
                    </div>
                    <AdOptRevImpLineGraph
                         compareRevImp={compareRevImp}
                         data={data}
                         loading = {loader}
                    />
               </div>
          </>
     )
}

export default AdOptRevImp