import React, { useEffect, useMemo } from 'react';
import PCard from '../../../../../../common/Card';
import { useDispatch, useSelector } from 'react-redux';
import AdOptDashboardAction from '../../../../AdOptimization/redux/actions';
import { RootState } from '../../../../../../../store/RootReducer';
import { Spin } from 'antd';
import {commaSeperator} from '../../../../../../../utils/Validation';
import DeltaBox from '../../../../../../common/DeltaBox';
import SubsDashboardAction from '../../../../Subscriptions/redux/actions';
import CrowdFundingDashboardActions from '../../../../CrowdFunding/redux/actions';
import QuickShopDashboardActions from '../../../../QuickShop/redux/actions';
import AdBlockDashboardActions from '../../../../AdBlockRecovery/redux/actions';

type Props = {
    siteId: string;
    startDate?: string;
    endDate?: string;
    revenueType?: string;
    comparison?: any;
    compare?: boolean;
    compare_start_date?: string;
    compare_end_date?: string;
    ad_server?: string;
};

const RevenueTopCards: React.FC<Props> = ({ siteId, startDate, endDate, revenueType, comparison, compare, compare_start_date, compare_end_date, ad_server }) => {
    const dispatch = useDispatch();

    const fetchTopAdOpt = useSelector((state: RootState) => state.adOptDashboard.topAdOpt);
    
    const subscriptionTopData = useSelector((state: RootState) => state.subsDashboard.subscritptionTopCard);
    const subscriptionLoading = useSelector((state: RootState) => state.subsDashboard.subscritptionTopCardLoader);

    const crowndFundingTopCards = useSelector((state: RootState) => state.crowdFundDashboard.crowdFundingTopCards);
    const crowndFundingLoader = useSelector((state: RootState) => state.crowdFundDashboard.crowdFundingTopCardsLoader);
    
    const quickShopTopCards = useSelector((state: RootState) => state.quickShopDashboard.quickShopTopCards);
    const quickShopLoader = useSelector((state: RootState) => state.quickShopDashboard.quickShopTopCardsLoader);

    // const adBlockRecoveryTopCards = useSelector((state: RootState) => state.adBlockDashboard.adBlockTopCards);
    // const adBlockRecoveryLoader = useSelector((state: RootState) => state.adBlockDashboard.adBlockTopCardsLoader);

    const fetchTopCpm = useSelector((state: RootState) => state.adOptDashboard.topCpm);
    const fetchTopCpmLoader = useSelector((state: RootState) => state.adOptDashboard.topCpmLoader);

    useEffect(() => {
        if (startDate && endDate) {
            dispatch(
                AdOptDashboardAction.fetchTopCardAdOpt({
                    site_id: siteId,
                    end_date: endDate,
                    start_date: startDate,
                    compare: compare,
                    compare_start_date: compare_start_date,
                    compare_end_date: compare_end_date,
                    ad_server: ad_server,
                }),
            );
            dispatch(
                AdOptDashboardAction.fetchTopCardRevenueCPM({
                    site_id: siteId,
                    end_date: endDate,
                    start_date: startDate,
                    revenue: revenueType,
                    compare: compare,
                    compare_start_date: compare_start_date,
                    compare_end_date: compare_end_date,
                    ad_server: ad_server,
                }),
            );
            dispatch(
                SubsDashboardAction.fetchSubscriptionTopCard({
                    site_id: siteId,
                    start_date: startDate,
                    end_date: endDate,
                    revenue: revenueType,
                    compare: compare,
                    compare_start_date: compare_start_date,
                    compare_end_date: compare_end_date,
                }),
            );
            dispatch(
                CrowdFundingDashboardActions.fetchCrowdFundTopCard({
                    start_date: startDate,
                    end_date: endDate,
                    compare: compare,
                    revenue: revenueType,
                    compare_start_date: compare_start_date,
                    compare_end_date: compare_end_date,
                    site_id: siteId,
                }),
            );
            dispatch(
                QuickShopDashboardActions.fetchQuickShopTopCard({
                    start_date: startDate,
                    end_date: endDate,
                    revenue: revenueType,
                    compare: compare,
                    compare_start_date: compare_start_date,
                    compare_end_date: compare_end_date,
                    site_id: siteId,
                }),
            );
            dispatch(
                AdBlockDashboardActions.fetchAdBlockTopCard({
                    start_date: startDate,
                    end_date: endDate,
                    compare: compare,
                    compare_start_date: compare_start_date,
                    compare_end_date: compare_end_date,
                    site_id: siteId,
                    // widget_id: widgetId,
                }),
            );
        }
    }, [startDate, endDate, revenueType, dispatch, compare, compare_start_date, compare_end_date, ad_server, siteId]);

    // console.log(quickShopTopCards?.previous_total_revenue);

    const tops = [
        {
            title: 'Revenue Total',
            percentage: 0,
            isUp: 0,
            percentageOriginalValue: 0,
            originalValue: 0,
            value: 0,
            loading: fetchTopCpmLoader,
            text: fetchTopAdOpt?.total_request !== undefined && `Previous Period: $${commaSeperator(String(Number(fetchTopCpm?.previous_total_revenue).toFixed(2)))}`,
            previousOriginalValue: 0,
            key: 'revenueTotal',
            gam:'ON'
        },
        {
            title: 'Revenue Ad Optimization',
            percentage: fetchTopCpm?.revenue_percentage && parseFloat(String(fetchTopCpm?.revenue_percentage)).toFixed(2),
            isUp: (fetchTopCpm?.revenue_percentage || 0) > 0,
            percentageOriginalValue: fetchTopCpm?.revenue_percentage || 0,
            originalValue: fetchTopCpm?.total_revenue || 0,
            value: fetchTopCpm?.total_revenue && `$${commaSeperator(String(Number(fetchTopCpm?.total_revenue).toFixed(2)))}`,
            loading: fetchTopCpmLoader,
            text: fetchTopAdOpt?.total_request !== undefined && `Previous Period: $${commaSeperator(String(Number(fetchTopCpm?.previous_total_revenue).toFixed(2)))}`,
            previousOriginalValue: fetchTopCpm?.previous_total_revenue || 0,
            key: 'revenue',
            gam:'ON'
        },
        {
            title: 'Revenue Subscriptions',
            percentage: subscriptionTopData?.revenue_percentage && parseFloat(String(subscriptionTopData?.revenue_percentage)).toFixed(2),
            isUp: (subscriptionTopData?.revenue_percentage || 0) > 0,
            percentageOriginalValue: subscriptionTopData?.revenue_percentage || 0,
            originalValue: subscriptionTopData?.total_revenue || 0,
            value: subscriptionTopData?.total_revenue && `$${commaSeperator(String(Number(subscriptionTopData?.total_revenue).toFixed(2)))}`,
            loading: subscriptionLoading,
            text: subscriptionTopData?.previous_total_revenue !== undefined && `Previous Period: $${commaSeperator(String(Number(subscriptionTopData?.previous_total_revenue).toFixed(2)))}`,
            previousOriginalValue: subscriptionTopData?.previous_total_revenue || 0,
            key: 'revenue',
            gam:'ON'
        },
        {
            title: 'Revenue Crowd Funding',
            percentage: crowndFundingTopCards?.earnings_percentage && parseFloat(String(crowndFundingTopCards?.earnings_percentage)).toFixed(2),
            isUp: (36 || 0) > 0,
            percentageOriginalValue: crowndFundingTopCards?.earnings_percentage || 0,
            originalValue: crowndFundingTopCards?.total_earnings || 0,
            value: crowndFundingTopCards?.total_earnings && `$${commaSeperator(parseFloat(String(crowndFundingTopCards?.total_earnings)).toFixed(2))}`,
            loading: crowndFundingLoader,
            text: crowndFundingTopCards?.previous_total_earnings !== undefined && `Previous Period: $${commaSeperator(parseFloat(String(crowndFundingTopCards?.previous_total_earnings)).toFixed(2))}`,
            previousOriginalValue: crowndFundingTopCards?.previous_total_earnings || 0,
            key: 'revenue',
            gam:'ON'
        },
        {
            title: 'Revenue Quickshop',
            percentage: quickShopTopCards?.total_rev_percentage && parseFloat(String(quickShopTopCards?.total_rev_percentage)).toFixed(2),
            isUp: (quickShopTopCards?.total_rev_percentage || 0) > 0,
            percentageOriginalValue: quickShopTopCards?.total_rev_percentage || 0,
            originalValue: quickShopTopCards?.total_revenue || 0,
            value: quickShopTopCards?.total_revenue && `$${commaSeperator(parseFloat(String(quickShopTopCards?.total_revenue)).toFixed(2))}`,
            loading: quickShopLoader,
            text: quickShopTopCards?.previous_total_revenue !== undefined && `Previous Period: $${commaSeperator(parseFloat(String(quickShopTopCards?.previous_total_revenue)).toFixed(2))}`,
            previousOriginalValue: quickShopTopCards?.previous_total_revenue || 0,
            key: 'revenue',
            gam:'ON'
        },
        // {
        //     title: 'AdBlock Recovery',
        //     percentage: adBlockRecoveryTopCards?.ab_pageview_percentage && parseFloat(String(adBlockRecoveryTopCards?.ab_pageview_percentage)).toFixed(2),
        //     isUp: (adBlockRecoveryTopCards?.ab_pageview_percentage || 0) > 0,
        //     value: adBlockRecoveryTopCards?.total_ab_pageview && `${commaSeperator(String(adBlockRecoveryTopCards?.total_ab_pageview))}`,
        //     loading: adBlockRecoveryLoader,
        //     text: adBlockRecoveryTopCards?.previous_total_ab_pageview !== undefined && `Previous Period: ${adBlockRecoveryLoader === false ? commaSeperator(String(adBlockRecoveryTopCards?.previous_total_ab_pageview)) : 0}`,
        //     key: 'revenue',
        //     gam:'ON'
        // },
    ];

    const totalRevenue = useMemo(() => {
        const revTotal = tops.reduce((acc, curr) => {
            acc = acc+curr.originalValue || 0;
            return acc;
        }, 0 )

        const revPrevious = tops.reduce((acc, curr) => {
            acc = acc+curr.previousOriginalValue || 0;
            return acc;
        }, 0 )

        const revPercentage = tops.reduce((acc, curr) => {
            // acc = acc+curr.percentageOriginalValue || 0;
            acc = ((revTotal - revPrevious)*100)/revPrevious || 0;
            return acc;
        }, 0 )
        return {'revPercentage':revPercentage, 'revTotal':revTotal, 'revPrevious': revPrevious};
    },[tops])

    return (
        <div className="py-8">
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4">
                {tops.map((t, index) => (
                    t?.gam === "ON" && 
                    <PCard
                        className="px-4 py-3 pb-2 w-full cursor-pointer topCard rounded-lg flex flex-col justify-between"
                        key={`${index}_${t.title}`}
                    >
                        <div className="flex justify-between items-center">
                            <p className="text-xs roboto-medium color-056433 title">{t.title}</p>
                            {!t.loading ? comparison && <DeltaBox width="w-auto" row={t.key==='revenueTotal'?parseFloat(String(totalRevenue.revPercentage)).toFixed(2):t.percentage} /> : <div className="h-7" />}
                        </div>
                        <div className="my-4 mb-3">
                            {!t.loading && <p className="roboto-medium font-semibold text-2xl value">{t.key==='revenueTotal'?`$${commaSeperator(String(Number(totalRevenue.revTotal || 0).toFixed(2)))}`:t.value}</p>}
                            {t.loading && (
                                <div className="flex justify-center items-center">
                                    <Spin />
                                </div>
                            )}
                        </div>
                        {t.text ? comparison && <p className="text-xs roboto text">{t.key==='revenueTotal'?`Previous Period: $${commaSeperator(parseFloat(String(totalRevenue.revPrevious)).toFixed(2))}`:t.text}</p> : <div className="h-4" />}
                    </PCard>
                ))}
            </div>
        </div>
    );
};

export default RevenueTopCards;
