import React from 'react'

type Props ={
     color:string;
}
const AdoptSvgFile: React.FC<Props> = ({color}) => {
     return (
          <div className='mr-[10px]'>
               <svg width="20px" height="20px" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.46436 12.0758V0.75C5.46436 0.336 5.10948 0 4.67046 0C4.23143 0 3.87655 0.336 3.87655 0.75V12.0758C2.06725 12.4245 0.700943 13.9395 0.700943 15.75C0.700943 17.5605 2.06725 19.0755 3.87655 19.4242V23.25C3.87655 23.664 4.23143 24 4.67046 24C5.10948 24 5.46436 23.664 5.46436 23.25V19.4242C7.27366 19.0755 8.63997 17.5605 8.63997 15.75C8.63997 13.9395 7.27366 12.4245 5.46436 12.0758ZM4.67046 18C3.35734 18 2.28875 16.9905 2.28875 15.75C2.28875 14.5095 3.35734 13.5 4.67046 13.5C5.98357 13.5 7.05216 14.5095 7.05216 15.75C7.05216 16.9905 5.98357 18 4.67046 18Z" fill={color} />
                    <path d="M14.1974 4.57578V0.750031C14.1974 0.336031 13.8425 3.05176e-05 13.4035 3.05176e-05C12.9644 3.05176e-05 12.6096 0.336031 12.6096 0.750031V4.57578C10.8003 4.92453 9.43396 6.43953 9.43396 8.25003C9.43396 10.0613 10.8003 11.5755 12.6096 11.9243V23.25C12.6096 23.664 12.9644 24 13.4035 24C13.8425 24 14.1974 23.664 14.1974 23.25V11.9243C16.0067 11.5755 17.373 10.0613 17.373 8.25003C17.373 6.43953 16.0067 4.92453 14.1974 4.57578ZM13.4035 10.5C12.0904 10.5 11.0218 9.49053 11.0218 8.25003C11.0218 7.00953 12.0904 6.00003 13.4035 6.00003C14.7166 6.00003 15.7852 7.00953 15.7852 8.25003C15.7852 9.49053 14.7166 10.5 13.4035 10.5Z" fill={color} />
                    <path d="M26.1052 15.75C26.1052 13.9395 24.7389 12.4245 22.9296 12.0758V0.750031C22.9296 0.336031 22.5748 3.05176e-05 22.1357 3.05176e-05C21.6967 3.05176e-05 21.3418 0.336031 21.3418 0.750031V12.0758C19.5325 12.4245 18.1662 13.9388 18.1662 15.75C18.1662 17.5605 19.5325 19.0755 21.3418 19.4243V23.25C21.3418 23.664 21.6967 24 22.1357 24C22.5748 24 22.9296 23.664 22.9296 23.25V19.4243C24.7389 19.0755 26.1052 17.5605 26.1052 15.75ZM22.1357 18C20.8226 18 19.754 16.9905 19.754 15.75C19.754 14.5095 20.8226 13.5 22.1357 13.5C23.4488 13.5 24.5174 14.5095 24.5174 15.75C24.5174 16.9905 23.4488 18 22.1357 18Z" fill={color} />
               </svg>
          </div>
     )
}

export default AdoptSvgFile