import React, { useEffect } from 'react'
import { GoPrimitiveDot } from 'react-icons/go';
import { DateTable, FavoriteOrRecent } from '../../../../AdOptimization/redux/types';
import moment from 'moment';
import { BiArrowBack } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PTable from '../../../../../../common/Table';
import CrowdFundingDashboardActions from '../../../redux/actions';
import { RootState } from '../../../../../../../store/RootReducer';

type Props = {
     startDate: any;
     endDate: any;
     revenueType: any;
     compare: any;
     compare_start_date: any;
     compare_end_date: any;
     siteId: any;
}
const TotalDonorsList: React.FC<Props> = ({ startDate, endDate, revenueType, compare, compare_end_date, compare_start_date, siteId }) => {
     const navigate = useNavigate();
     const dispatch = useDispatch();
     const totalDonorsList = useSelector((state:RootState)=> state.crowdFundDashboard.crowdFundingTotalDonorsList);
     const totalDonorsListLoader = useSelector((state:RootState)=> state.crowdFundDashboard.crowdFundingTotalDonorsListLoader);
     const onClickBack = () => {
          navigate(-1);
     };

     useEffect(() => {
          if (startDate && endDate) {
               dispatch(
                    CrowdFundingDashboardActions.fetchCrowdFundTotalDonorsList({
                         "start_date": startDate,
                         "end_date": endDate,
                         "compare": false,
                         "revenue": revenueType,
                         "compare_start_date": compare_start_date,
                         "compare_end_date": compare_end_date,
                         "site_id": siteId
                    })
               )
          }
     }, [dispatch, startDate, endDate, revenueType, compare, compare_end_date, compare_start_date, siteId])

     const columns = [
          {
               dataIndex: 'site_name',
               title: 'Site',
               width: 250,
               fixed: 'left',
               render: (text: any, row: FavoriteOrRecent, index: number) => {
                    return <div className="flex items-center justify-start">{text}</div>;
               },
               sorter: (a: any, b: any) => String(a.site_name).localeCompare(String(b.site_name)),
          },
          {
               dataIndex: 'name',
               title: 'Name',
               render: (text: any, row: FavoriteOrRecent) => (
                    <div
                         className="flex items-center justify-start gap-2 text-green-600 underline"
                         onClick={() => {
                              //  setOpen(true);
                              //  setModalKeys(row);
                         }}
                    >
                         <span>{row?.status === 0 ? <GoPrimitiveDot size={24} color="red" /> : <GoPrimitiveDot size={24} color="green" />}</span>
                         <span>{String(text || ' ')}</span>
                    </div>
               ),
               sorter: (a: any, b: any) => String(a.name).localeCompare(String(b.name)),
          },
          {
               dataIndex: 'email',
               title: 'Email',
               render: (text: any, row: FavoriteOrRecent) => (
                    <div
                         className="flex items-center justify-start gap-2 text-green-600 underline"
                         onClick={() => {
                              //  setOpen(true);
                              //  setModalKeys(row);
                         }}
                    >
                         <span>{row?.status === 0 ? <GoPrimitiveDot size={24} color="red" /> : <GoPrimitiveDot size={24} color="green" />}</span>
                         <span>{String(text || ' ')}</span>
                    </div>
               ),
               sorter: (a: any, b: any) => String(a.email).localeCompare(String(b.email)),
          },
          {
               dataIndex: 'date',
               title: 'Signed Up',
               width: 250,
               render: (text: any, row: FavoriteOrRecent) => (
                    <div className="flex items-center justify-center gap-2">
                         <span>{text}</span>
                    </div>
               ),
               sorter: (a: DateTable, b: DateTable) => moment(a.date).unix() - moment(b.date).unix(),
          },
          {
               dataIndex: 'total_earnings',
               title: 'Total Earnings',
               width: 150,
               render: (text: any, row: FavoriteOrRecent) => (
                    <div className="flex items-center justify-center gap-2">
                         <span>${text}</span>
                    </div>
               ),
               sorter: (a: any, b: any) => parseFloat(a.total_earnings) - parseFloat(b.total_earnings),
          },

     ];
     return (
          <div>
               <div className="relative flex justify-between items-center pb-4">
                    <div className="flex items-start mt-4">
                         <div onClick={onClickBack} className="bg-[#B4D0C133] cursor-pointer h-10 flex items-center px-4 rounded-lg mr-2 mt-1">
                              <BiArrowBack className="mr-2" />
                              <span>Back</span>
                         </div>
                    </div>

               </div>

               <PTable className="dashboard_table"
                    columns={columns}
                    data={totalDonorsList|| []}
                    loading={totalDonorsListLoader}
                    pagination={{ isShow: true }} rowKey={(d: any) => `${Math.random()}`} />
          </div>
     )
}

export default TotalDonorsList