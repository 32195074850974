import { Empty, Spin } from 'antd';
import moment from 'moment';
import React from 'react'
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { commaSeperator, numberFormatter } from '../../../../../../utils/Validation';

type Props = {
     compareRevImp: any
     data: any;
     loading: any
}

const AdOptRevImpLineGraph: React.FC<Props> = ({ compareRevImp, data, loading }) => {
     const formatter1 = (value: string | number) => `$${String(numberFormatter(Number(value)))}`;
     const formatter2 = (value: string | number) => `${String(numberFormatter(Number(value)))}`;
     return (
          <div className="py-6 bg-white pb-2  rounded-xl drop-shadow-md">
               {!loading && (
                    <>
                    <div className='flex justify-between mx-10 pb-3'>
                         <div className='font-[600]'>Revenue</div>
                         <div className='font-[600]'>Impressions</div>
                    </div>
                         {data?.length !== 0 ? (
                              <ResponsiveContainer height={300} width="100%">
                                   <LineChart
                                        data={data?.map((d: any) => {
                                             return {
                                                  ...d,
                                                  total_revenue: parseFloat(d.total_revenue),
                                                  total_impressions: parseFloat(d.total_impressions),
                                             };
                                        })}
                                        margin={{ top: 10, right: 20, bottom: 20, left: 20 }}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="date" />
                                        <YAxis yAxisId={"left"} tickFormatter={formatter1} tick={{ fill: '#615E83', fontSize: '0.8rem', fontFamily: 'Inter'}} />
                                        <YAxis yAxisId={"right"} tickFormatter={formatter2} orientation='right' tick={{ fill: '#615E83', fontSize: '0.8rem', fontFamily: 'Inter'}} />
                                        <Tooltip content={<CustomTooltip labelClassName={"$"} />} />
                                        <Legend />
                                        {compareRevImp?.rev ? <Line yAxisId={"left"} type="monotone" name="Revenue" dataKey="total_revenue" stroke="#00a7a7" /> : null}
                                        {compareRevImp?.imp ? <Line yAxisId={"right"} type="monotone" name="Impressions" dataKey="total_impressions" stroke="#418040" /> : null}

                                   </LineChart>
                              </ResponsiveContainer>
                         ) : (
                              <div className="flex justify-center items-center h-40">
                                   <Empty description={'No data found'} />
                              </div>
                         )}
                    </>
               )}
               {loading && (
                    <div className="flex align-center justify-center">
                         <Spin className="m-auto" />
                    </div>
               )}
          </div>
     )
}

export default AdOptRevImpLineGraph

const CustomTooltip = ({ active, payload, label, labelClassName, wrapperClassName }: any) => {
     if (active) {
          return (
               <div className="rounded  bg-white  border-[1px] border-[grey]" >
                    <div className=" rounded px-4 py-1" style={{ background: 'rgb(255,255,255)', borderBottom: '1px solid #333' }}>
                         <p className="label text-[18px] roboto-medium text-black">{moment((payload && payload.length > 0 && payload[0].payload?.date) || label).format(`DD-MMM-YYYY`)}</p>
                    </div>
                    <div className=" px-4 py-1 text-center">
                         {payload?.map((p: any, index: number) => {
                              return (
                                   <div key={`${index}_${p.name}`} className={`  flex justify-between items-center`}>
                                        {
                                             p.name === "Revenue" ? <p className={`text-s text-[#00a7a7]`}>{p.name} &nbsp; &nbsp; &nbsp;</p> :
                                                  p.name === "Impressions" ? <p className={`text-s text-[#418040]`}>{p.name} &nbsp; &nbsp; &nbsp;</p> : null

                                        }
                                        <p>{p.name === "Revenue" ? ` ${labelClassName}${commaSeperator(parseFloat(String(p.value || 0)).toFixed(2))}` : commaSeperator(String(p.value || 0))}</p>
                                   </div>
                              )
                         })}
                    </div>
               </div>
          );
     }
     return null;
};