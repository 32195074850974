import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import PTabs from '../../common/Tabs';
import ComparisonSwitch from '../../common/ComparisonSwitch';
import DateFilter from '../dashboard/AdOptimization/components/DateFilter';
import NetGrossFilter from '../dashboard/AdOptimization/components/NetGrossFilter';
import SubscriptionsDashboard from './Subscriptions';
import CrowdFundingDashboard from './CrowdFunding';
import AdBlockRecoveryDashboard from './AdBlockRecovery';
import QuickShopDashboard from './QuickShop';
import DashboardOverview from './DashboardOverview';
import AdOptimizationDashboard from './AdOptimization';
import AdoptSvgFile from '../../../assets/icons/sidebaricons/AdoptSvgFile';
import SubscriptionSvgFile from '../../../assets/icons/sidebaricons/SubscriptionSvgFile';
import CrowdFundSvgFile from '../../../assets/icons/sidebaricons/CrowdFundSvgFile';
import AdRecoverySvgFile from '../../../assets/icons/sidebaricons/AdRecoverySvgFile';
import QuickShopSvgFile from '../../../assets/icons/sidebaricons/QuickShopSvgFile';
import { RootState } from '../../../store/RootReducer';
import { Select } from 'antd';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { HEADERMENU_PATH } from '../../../routes/RoutesURL';

export const API_date_format = 'YYYY/MM/DD';
const { Option } = Select;

const Dashboard: React.FC = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const dataAll = useSelector((state: RootState) => state.adOptDashboard.allSites);
    const allSitesLoading = useSelector((state: RootState) => state.adOptDashboard.allSitesLoading);
    const [siteId, setSiteId] = useState(0);
    const [activeTab, setActiveTab] = useState(params.route ?? 'overview');
    const [applyDate, setApplyDate] = useState('last7Day');
    const [revenueType, setRevenueType] = useState<string | undefined>('gross');
    const [customEndDate, setCustomEndDate] = useState<string | undefined>(undefined);
    const [customStartDate, setCustomStartDate] = useState<string | undefined>(undefined);
    const [compare, setCompare] = useState(false);
    const [dstring2, setDatestring2] = useState({ sdate: '', edate: '' });
    const [compaison, setComparison] = useState(true);
    const [adServer, serAdServer] = useState(false);
    const [allSiteDataList, setAllSiteDataList] = useState<any>([]);
    const endDate = useMemo(() => {
        switch (applyDate) {
            case 'last1Day':
            case 'last7Day':
            case 'last30Day':
                return moment().startOf('day').subtract(1, 'day').format(API_date_format);
            default:
                return customEndDate;
        }
    }, [applyDate, customEndDate]);

    const startDate = useMemo(() => {
        switch (applyDate) {
            case 'last1Day':
                return moment().startOf('day').subtract(1, 'day').format(API_date_format);
            case 'last7Day':
                return moment().subtract(7, 'day').format(API_date_format);
            case 'last30Day':
                return moment().subtract(30, 'day').format(API_date_format);
            default:
                return customStartDate;
        }
    }, [applyDate, customStartDate]);

    const getComparison = (e: any) => {
        setComparison(e);
    };
    const getAdServer = (e: any) => {
        serAdServer(e);
    };

    const onChangeTab = (tab: string) => {
        if (searchParams.has('key')) {
            searchParams.delete('key');
            setSearchParams(searchParams);
        }
        navigate(`${HEADERMENU_PATH.newDashboard}/${tab}`);
        setActiveTab(tab);
    };

    useEffect(() => {
        if (params.route != null) {
            setActiveTab(params.route);
        }
    }, [params.route]);

    const updateSite = (siteId: any) => {
        setSiteId(siteId);
    }

    const tabComponents = useMemo(() => {
        switch (activeTab) {
            case 'overview':
                return <DashboardOverview
                    siteId={siteId}
                    startDate={startDate}
                    endDate={endDate}
                    compaison={compaison}
                    compare={compare}
                    compare_start_date={compare === false ? '' : dstring2.sdate}
                    compare_end_date={compare === false ? '' : dstring2.edate}
                    revenueType={revenueType}
                    applyDate={applyDate}
                    adServer={adServer}
                />;
            case 'adoptimization':
                return <AdOptimizationDashboard
                    siteId={siteId}
                    startDate={startDate}
                    endDate={endDate}
                    compaison={compaison}
                    compare={compare}
                    compare_start_date={compare === false ? '' : dstring2.sdate}
                    compare_end_date={compare === false ? '' : dstring2.edate}
                    revenueType={revenueType}
                    applyDate={applyDate}
                    adServer={adServer}
                    updateSite={updateSite} />;
            case 'subscriptions':
                return <SubscriptionsDashboard
                    siteId={siteId}
                    startDate={startDate}
                    endDate={endDate}
                    compare={compare}
                    compaison={compaison}
                    compare_start_date={compare === false ? '' : dstring2.sdate}
                    compare_end_date={compare === false ? '' : dstring2.edate}
                    revenueType={revenueType} />;
            case 'crowdFunding':
                return <CrowdFundingDashboard
                    siteId={siteId}
                    startDate={startDate}
                    endDate={endDate}
                    compare={compare}
                    compaison={compaison}
                    compare_start_date={compare === false ? '' : dstring2.sdate}
                    compare_end_date={compare === false ? '' : dstring2.edate}
                    revenueType={revenueType} />;
            case 'quickShop':
                return <QuickShopDashboard
                    siteId={siteId}
                    startDate={startDate}
                    endDate={endDate}
                    compare={compare}
                    compaison={compaison}
                    compare_start_date={compare === false ? '' : dstring2.sdate}
                    compare_end_date={compare === false ? '' : dstring2.edate}
                    revenueType={revenueType} />;
            case 'adBlockRecovery':
                return <AdBlockRecoveryDashboard
                    siteId={siteId}
                    startDate={startDate}
                    endDate={endDate}
                    compare={compare}
                    compaison={compaison}
                    compare_start_date={compare === false ? '' : dstring2.sdate}
                    compare_end_date={compare === false ? '' : dstring2.edate}
                    revenueType={revenueType} />;
            default:
                return null;
        }
    }, [activeTab, siteId, startDate, endDate, compaison, compare, dstring2, adServer, revenueType, applyDate]);

    const handleChange = (e: any) => {
        setSiteId(e);
    }

    useEffect(() => {
        let newData = { site_id: 0, site_name: 'All Sites', favourite: 0, gam: '0' }
        let dum = [newData, ...dataAll];
        setAllSiteDataList(dum);
    }, [dataAll])


    return (
        <div className="dashboard ">
            <div className="relative pb-10 flex flex-wrap justify-between items-center gap-5">
                <div className='max-w-[35%] w-[35%]'>
                    <div className="flex flex-col w-full" id="dateFilter">
                        <Select
                            value={siteId}
                            size="large"
                            onChange={handleChange}
                            optionFilterProp="children"
                            loading={allSitesLoading}
                            showSearch
                            className={`customSelector border rounded-lg overflow-auto font-[montserrat]`}
                        >
                            {allSiteDataList?.map((item: any, key: any) => {
                                return (
                                    <Option
                                        key={key}
                                        value={item?.site_id}
                                        title={'location'}
                                        className="font-[montserrat]">
                                        {item.site_name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </div>
                </div>
                <div className="flex flex-wrap items-center gap-5 ">
                    <ComparisonSwitch title="Show Comparison" compaison={compaison} onChange={getComparison} />
                    <ComparisonSwitch title="GAM Only" compaison={adServer} onChange={getAdServer} />
                    <NetGrossFilter revenueType={revenueType} setRevenueType={setRevenueType} siteId={siteId}/>
                    <DateFilter
                        applyDate={applyDate}
                        setApplyDate={setApplyDate}
                        setCustomEndDate={setCustomEndDate}
                        setCustomStartDate={setCustomStartDate}
                        setDatestring2={setDatestring2}
                        dstring2={dstring2}
                        compare={compare}
                        setCompare={setCompare}
                        showCompare={compaison}
                    />
                </div>
            </div>
            <div className="mt-8">
                <PTabs
                    activeTab={activeTab}
                    setActiveTab={onChangeTab}
                    tabs={[
                        { key: 'overview', icon: <AdoptSvgFile color={activeTab === 'overview' ? '#056433' : '#a8a8a8'} />, title: ' Overview' },
                        { key: 'adoptimization', icon: <AdoptSvgFile color={activeTab === 'overview' ? '#056433' : '#a8a8a8'} />, title: 'Ad Optimization' },
                        { key: 'subscriptions', icon: <SubscriptionSvgFile color={activeTab === 'overview' ? '#056433' : '#a8a8a8'} />, title: 'Subscriptions' },
                        { key: 'crowdFunding', icon: <CrowdFundSvgFile color={activeTab === 'overview' ? '#056433' : '#a8a8a8'} />, title: 'Crowd Funding' },
                        // { key: 'quickShop', icon: <QuickShopSvgFile color={activeTab === 'overview' ? '#056433' : '#a8a8a8'} />, title: 'Quickshop' },
                        { key: 'adBlockRecovery', icon: <AdRecoverySvgFile color={activeTab === 'overview' ? '#056433' : '#a8a8a8'} />, title: 'Ad Block Recovery' },
                    ]}
                />
                <div className="px-2 py-4 pt-2">{tabComponents}</div>
            </div>
        </div>
    );
};

export default Dashboard;
