import React from 'react';
import { XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid, LineChart, Legend, Line } from 'recharts';
// import { VscCircleFilled } from 'react-icons/vsc';
import { Empty, Spin } from 'antd';
// import moment from 'moment';
// import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { commaSeperator, numberFormatter } from '../../../../../../../utils/Validation';
import moment from 'moment';

type Props = {
    title: string;
    value: string;
    data: any;
    loading: boolean;
    compaison?: boolean;
    suffix?: string;
    prefix?: string;
    compareRevs?: any;
};

const OverviewLineAreaChart: React.FC<Props> = ({ title, value, data, loading, compaison, suffix, prefix, compareRevs }) => {
    const formatter = (value: string | number) => `${prefix ? prefix : ' '}${String(numberFormatter(Number(value)))}`;
    return (
        <div className="py-6 bg-white pb-2  rounded-xl drop-shadow-md">
            {!loading && (
                <>
                    <div className="flex flex-row justify-between mx-6 text-gray-500">
                        <div className="text-[#9291A5] inter">{title}</div>
                        <div className="flex flex-row items-center">
                            {/* <div>{compaison ? <VscCircleFilled color="#979797" /> : null}</div> */}
                            {/* <div className="inter text-[#615E83] text-sm">{compaison ? 'Previous Period' : null}</div> */}
                        </div>
                    </div>
                    {data?.length !== 0 ? (
                        <ResponsiveContainer height={280} width="100%">
                            <LineChart
                                width={730}
                                height={250}
                                data={data?.map((d: any) => {
                                    return {
                                        ...d,
                                        total_rev: parseFloat(d.total_rev),
                                        ad_revenue: parseFloat(d.ad_revenue),
                                        subs_revenue: parseFloat(d.subs_revenue),
                                        crow_revenue: parseFloat(d.crow_revenue)
                                    };
                                })}
                                margin={{ top: 10, right: 15, bottom: 40, left: 15 }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" />
                                <YAxis tickFormatter={formatter} tick={{ fill: '#615E83', fontSize: '0.8rem', fontFamily: 'Inter' }} />
                                <Tooltip content={<CustomTooltip labelClassName={"$"} />} />
                                {/* <Tooltip/> */}
                                <Legend />
                                {compareRevs?.total ? <Line type="monotone" name="Total Revenue" dataKey="total_rev" stroke="#00a7a7" /> : null}
                                {compareRevs?.adopt ? <Line type="monotone" name="Ad Optimization" dataKey="ad_revenue" stroke="#418040" /> : null}
                                {compareRevs?.subs ? <Line type="monotone" name="Subscriptons" dataKey="subs_revenue" stroke="#b7cf30" /> : null}
                                {compareRevs?.crowd ? <Line type="monotone" name="Crowd Funding" dataKey="crow_revenue" stroke="#05ff00" /> : null}
                            </LineChart>
                        </ResponsiveContainer>
                    ) : (
                        <div className="flex justify-center items-center h-40">
                            <Empty description={'No data found'} />
                        </div>
                    )}
                </>
            )}
            {loading && (
                <div className="flex align-center justify-center">
                    <Spin className="m-auto" />
                </div>
            )}
        </div>
    );
};

export default OverviewLineAreaChart;

const CustomTooltip = ({ active, payload, label, labelClassName, wrapperClassName }: any) => {
    if (active) {
        return (
            <div className="rounded  bg-white  border-[1px] border-[grey]" >
                <div className=" rounded px-4 py-1" style={{ background: 'rgb(255,255,255)', borderBottom: '1px solid #333' }}>
                    <p className="label text-[18px] roboto-medium text-black">{moment((payload && payload.length > 0 && payload[0].payload?.date) || label).format(`DD-MMM-YYYY`)}</p>
                </div>
                <div className=" px-4 py-1 text-center">
                    {payload?.map((p: any, index: number) => {
                        return (
                            <div key={`${index}_${p.name}`} className={`  flex justify-between items-center`}>
                                {
                                    p.name === "Total Revenue"?<p className={`text-s text-[#00a7a7]`}>{p.name} &nbsp; &nbsp; &nbsp;</p>:
                                    p.name === "Ad Optimization"?<p className={`text-s text-[#418040]`}>{p.name} &nbsp; &nbsp; &nbsp;</p>:
                                    p.name === "Subscriptons"? <p className={`text-s text-[#b7cf30]`}>{p.name} &nbsp; &nbsp; &nbsp;</p>:
                                    p.name === "Crowd Funding"?<p className={`text-s text-[#05ff00]`}>{p.name} &nbsp; &nbsp; &nbsp;</p>:null
                                }
                                <p>{labelClassName ? ` ${labelClassName} ${commaSeperator(parseFloat(String(p.value || 0)).toFixed(2))}` : commaSeperator(String(p.value || 0))}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }
    return null;
};


// const CustomTooltip = ({ active, payload, label, labelClassName, wrapperClassName }: any) => {
//     if (active) {
//         return (
//             <div className="shadow rounded" style={{ background: 'rgba(30, 30, 30, 0.8)' }}>
//                 <div className="px-4 py-1" style={{ background: 'rgba(0, 0, 0, 0.7)', borderBottom: '1px solid #333' }}>
//                     <p className="label text-xs roboto-medium text-white">{moment((payload && payload.length > 0 && payload[0].payload?.date) || label).format(`DD-MMM-YYYY`)}</p>
//                 </div>
//                 <div className="desc px-4 py-1 text-center">
//                     {payload?.map((p:any, index:number) => (
//                         <div key={`${index}_${p.name}`} className={'text-xs text-white flex justify-between items-center'}>
//                             <p>{p.name } &nbsp; &nbsp; &nbsp;</p>
//                             {/* <p>{labelClassName ? ` ${labelClassName}${commaSeperator(parseFloat(String(p.value || 0)).toFixed(2))}` : commaSeperator(String(p.value || 0))}</p> */}
//                             <p>{labelClassName ? ` ${labelClassName} ${commaSeperator(parseFloat(String(p.value || 0)).toFixed(2))}` : commaSeperator(String(p.value || 0))}</p>
//                         </div>
//                     ))}
//                 </div>
//             </div>
//         );
//     }
//     return null;
// };
