import { Badge, Input, Menu, MenuProps } from "antd"
import { useState } from "react";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2"
import PModal from "../../../common/Modal";
import { RoundButton } from "../../../common/Button";

type Props = {
     setFilterQuery: any;
     filterQuery: any;
}
type MenuItem = Required<MenuProps>['items'][number];

const AnalyticsFilter: React.FC<Props> = ({setFilterQuery,filterQuery}) => {
     const [showModal, setShowModal] = useState(false) // for opening and clsing filter modald
     const [selectedKeys, setSelectedKeys] = useState(['puid']) // selected menu key.

     const items: MenuItem[] = [
          getItem('PUID', 'puid'),
          getItem('Site Name', 'site_name'),
          getItem('Page Visited', 'page_visited'),
          getItem('Page Category', 'page_category'),
          getItem('Time Stamp', 'time_stamp'),
          getItem('Subscriber Email', 'subscriber_email'),
          getItem('CrowdFunding Email', 'crowdfunding_email'),
          getItem('Ad Block', 'adblock'),
     ];
     const onClickMenuItem: MenuProps['onClick'] = (e) => {
          setSelectedKeys([e.key]);
     };


     return (
          <div>
               <div className="w-[100%] relative flex flex-row-reverse pr-1">
                    <Badge count={0} offset={[-5, 5]} className='' color='#056433'>
                         <HiOutlineAdjustmentsHorizontal
                              onClick={() => setShowModal(!showModal)}
                              z={1001} size={32} />
                    </Badge>
               </div>
               <PModal
                    title={
                         <div className='flex gap-4 items-center'>
                              <div>Filter</div>
                              {/* {
                                   getCount > 0 &&
                                   <div className='flex gap-4 items-center'>
                                        <div className='w-[80px] py-1 rounded-full bg-[#056433] font-[Roboto] font-[400] text-[white] text-[12px] flex items-center justify-center'>{getCount} Selected</div>
                                        <div className='text-[14px] cursor-pointer'
                                             onClick={() => setLocalFilter({
                                                  site: [],
                                                  live_products: [],
                                                  publisher: [],
                                                  status: [],
                                                  version: [],
                                                  account_manager: []
                                             })}
                                        >CLEAR ALL</div>
                                   </div>
                              } */}
                         </div>
                    }
                    open={showModal}
                    setOpen={setShowModal}
                    width='750px'
                    bodyStyle={{ height: '500px', overflowY: 'scroll' }}
                    className='noscrollbar'
                    footer={
                         <div className='flex gap-3 justify-center pb-5 items-center w-full '>
                              <div className="border rounded-3xl border-green-800">
                                   <RoundButton
                                        light={true}
                                        title='Cancel'
                                        className={'w-[120px] text-[14px]'}
                                        onClick={() => setShowModal(false)}
                                   />
                              </div>
                              <div>
                                   <RoundButton
                                        title='Apply'
                                        className='w-[120px]'
                                        onClick={()=>{}}
                                   />
                              </div>
                         </div>
                    }
               >
                    <div className=''>
                         <div className='flex '>
                              <div className='w-1/2 '>
                                   <Menu
                                        mode="inline"
                                        onClick={onClickMenuItem}
                                        selectedKeys={selectedKeys}
                                        className={`bg-transparent sidebar`}
                                        style={{ borderInlineEnd: 'none', fontSize: '16px', fontFamily: 'Roboto', fontWeight: '600' }}
                                   >
                                        {
                                             items?.map((itm, index) => {
                                                  const data: any = itm;
                                                  return (
                                                       data && <Menu.Item key={data?.key}>
                                                            <div className='flex gap-3 items-center'>
                                                                 <div>{data?.label}</div>
                                                                 {/* {
                                                                      data?.key === 'site' ? localFilter?.site?.length > 0 && <Badge color='#056433' count={localFilter?.site?.length}></Badge> :
                                                                           data?.key === 'live_products' ? localFilter?.live_products?.length > 0 && <Badge color='#056433' count={localFilter?.live_products?.length}></Badge> :
                                                                                data?.key === "publisher" ? localFilter?.publisher?.length > 0 && <Badge color='#056433' count={localFilter?.publisher?.length}></Badge> :
                                                                                     data?.key === 'status' ? localFilter?.status?.length > 0 && <Badge color='#056433' count={localFilter?.status?.length}></Badge> :
                                                                                          data?.key === "version" ? localFilter?.version?.length > 0 && <Badge color='#056433' count={localFilter?.version?.length}></Badge> :
                                                                                               localFilter?.account_manager?.length > 0 && <Badge color='#056433' count={localFilter?.account_manager?.length} ></Badge>
                                                                 } */}
                                                            </div>
                                                       </Menu.Item>
                                                  )
                                             })
                                        }
                                   </Menu>
                              </div>
                              <div className='w-1/2 bg-[#eaf2ed] rounded-lg'>
                                   <div className='w-full searchBox p-4'>
                                        <Input.Search
                                             placeholder="Search for Sites / Clients"
                                             onKeyDown={(event) => {
                                                  if (event.key === 'Enter') {
                                                      // onClickListSearch();
                                                  }
                                             }}
                                             size="large"
                                             // value={searchInput}
                                             // className="h-10 border-none  w-full"
                                             // onSearch={onClickListSearch}
                                             // enterButton
                                             // onChange={(e) => setSearchInput(e.target.value)}
                                        />
                                   </div>
                                   {/* <div className='h-[390px] overflow-auto'>
                                        {
                                             currentList?.map((item: any, index: any) => (
                                                  <div key={index} className='pl-5 pb-3 flex gap-4 items-center font-[Roboto] font-[500] text-[16px]'>
                                                       <Checkbox
                                                            checked={findCheck(item?.value)}
                                                            className='customCheckBox2'
                                                            onChange={(e) => onChangeCheckbox(e.target.checked, item?.value)}
                                                       />
                                                       <div className='font-[Roboto]'>{item?.title}</div>
                                                  </div>
                                             ))
                                        }
                                        {
                                             (onboardSiteListLoader || publisherListLoader || prebidVersionListLoader || accountManagerListLoader) && <div className='w-full h-[200px] flex justify-center items-center'><Spin /></div>
                                        }
                                   </div> */}
                              </div>
                         </div>
                    </div>
               </PModal>
          </div>
     )
}

export default AnalyticsFilter

function getItem(label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[], type?: 'group'): MenuItem {
     return {
          key,
          icon,
          children,
          label,
          type,
     } as MenuItem;
}