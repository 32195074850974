import moment from "moment";
import PTable from "../../../../common/Table";

const AnalyticsTable:React.FC = ()=>{

     const columns = [
          {
               dataIndex: 'puid',
               title: 'PUID',
               width: '',
               // render: (text: any, row: any) => <div className="flex items-center justify-center text-[green]">{`${moment(text).format('YYYY-MM-DD  h:mm A')}`}</div>,
               // sorter: (a: any, b: any) => moment(a.date_updated).unix() - moment(b.date_updated).unix(),
           },
   
           {
               dataIndex: 'site_name',
               title: 'Site Name',
               width: '',
               // render: (text: any, row: any) => <div className="flex items-center justify-start text-[green] truncate">{row.business_name || text}</div>,
               // sorter: (a: any, b: any) => String(a.business_name || a.publisher_name).localeCompare(String(b.business_name || b.publisher_name)),
           },
           {
               dataIndex: 'page_visited',
               title: 'Page Visited',
               width: '',
               // render: (text: any, row: any) => <div className="flex items-center justify-end text-[green]">{text}</div>,
               // sorter: (a: any, b: any) => String(a.prebid_version).localeCompare(String(b.prebid_version)),
           },
           {
               dataIndex: 'page_category',
               title: 'Page Category',
               width: '',
               // render: (text: any, row: any) => <div className="flex items-center justify-end text-[green]">{text}</div>,
               // sorter: (a: any, b: any) => String(a.prebid_version).localeCompare(String(b.prebid_version)),
           },
           {
               dataIndex: 'time_stamp',
               title: 'Time Stamp',
               width: '',
               // render: (text: any, row: any) => <div className="flex items-center justify-end text-[green]">{text}</div>,
               // sorter: (a: any, b: any) => String(a.prebid_version).localeCompare(String(b.prebid_version)),
           },
           {
               dataIndex: 'subscriber_email',
               title: 'Subscriber Email',
               width: '',
               // render: (text: any, row: any) => <div className="flex items-center justify-end text-[green]">{text}</div>,
               // sorter: (a: any, b: any) => String(a.prebid_version).localeCompare(String(b.prebid_version)),
           },
           {
               dataIndex: 'crowdfunding_email',
               title: 'Crowdfunding Email',
               width: '',
               // render: (text: any, row: any) => <div className="flex items-center justify-end text-[green]">{text}</div>,
               // sorter: (a: any, b: any) => String(a.prebid_version).localeCompare(String(b.prebid_version)),
           },
           {
               dataIndex: 'adblock',
               title: 'Ad Block',
               width: '',
               // render: (text: any, row: any) => <div className="flex items-center justify-end text-[green]">{text}</div>,
               // sorter: (a: any, b: any) => String(a.prebid_version).localeCompare(String(b.prebid_version)),
           },
          
     ]
     return (
          <div>
               <PTable columns={columns} data={[]} pagination={{isShow:true}}/>
          </div>
     )
}

export default AnalyticsTable;