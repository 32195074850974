import React from 'react';
import {useSearchParams} from 'react-router-dom';
import RevenueTopCards from './components/dashboard/revenueTopCards';
import SitesListOfAdOptimisation from './ListOfSites';
// import AdOptimizationTabs from './components/dashboard/adOptimizationTabs';
// import SubscriptionTabs from './components/dashboard/subscriptionTabs';
// import CrowdFundingTabs from './components/dashboard/crowdfundingTabs';
// import AdRecoverBlockTabs from './components/dashboard/adBlockRecoveryTabs';
// import QuickShopTabs from './components/dashboard/quickshopTabs';
// import LineAreaChart from './components/SiteDetails/Charts/LineAreaChart';
import RevenueOverview from './components/dashboard/revenueOverview';

export const API_date_format = 'YYYY/MM/DD';
type Props = {
    siteId: any;
    startDate: any;
    endDate: any;
    compaison?: any;
    compare?: any;
    compare_start_date?: any;
    compare_end_date?: any;
    revenueType?: any;
    applyDate?: any;
    adServer?: boolean;
};

const DashboardOverview: React.FC<Props> = ({ siteId, startDate, endDate, compaison, compare, compare_start_date, compare_end_date, revenueType, applyDate, adServer }) => {
    const [searchParams] = useSearchParams();
    
    if (searchParams.get('key') === 'revenue' || searchParams.get('key') === 'monetizedimpression' || searchParams.get('key') === 'adrequest' || searchParams.get('key') === 'cpm' || searchParams.get('key') === 'fetchTopALl') {
        return <SitesListOfAdOptimisation />;
    }
    return (
        <div className="dashboard mx-5">
            <RevenueTopCards
                siteId={siteId}
                startDate={startDate}
                endDate={endDate}
                revenueType={revenueType}
                comparison={compaison}
                compare={compare}
                compare_start_date={compare === false ? '' : compare_start_date}
                compare_end_date={compare === false ? '' : compare_end_date}
                ad_server={adServer ? 'ON' : 'OFF'}
            />
            {/* <div className="font-bold text-[28px] mx-2">Revenue Overview</div> */}
            <RevenueOverview 
                siteId={siteId}
                startDate={startDate}
                endDate={endDate}
                revenueType={revenueType}
                compaison={compaison}
                compare={compare}
                compare_start_date={compare === false ? '' : compare_start_date}
                compare_end_date={compare === false ? '' : compare_end_date}
                adServer={adServer ? 'ON' : 'OFF'}/>
            {/* <div className="font-bold text-[28px] mx-2">Ad Optimization</div>
            <AdOptimizationTabs
                siteId={siteId}
                startDate={startDate}
                endDate={endDate}
                revenueType={revenueType}
                comparison={compaison}
                compare={compare}
                compare_start_date={compare === false ? '' : compare_start_date}
                compare_end_date={compare === false ? '' : compare_end_date}
                ad_server={adServer ? 'ON' : 'OFF'}
            />
            <div className="font-bold text-[28px] mx-2">Subscriptions</div>
            <SubscriptionTabs
                siteId={siteId}
                startDate={startDate}
                endDate={endDate}
                revenueType={revenueType}
                comparison={compaison}
                compare={compare}
                compare_start_date={compare === false ? '' : compare_start_date}
                compare_end_date={compare === false ? '' : compare_end_date}
                ad_server={adServer ? 'ON' : 'OFF'}
            />
            
            <div className="font-bold text-[28px] mx-2">Crowdfunding </div>
            <CrowdFundingTabs
                siteId={siteId}
                startDate={startDate}
                endDate={endDate}
                revenueType={revenueType}
                comparison={compaison}
                compare={compare}
                compare_start_date={compare === false ? '' : compare_start_date}
                compare_end_date={compare === false ? '' : compare_end_date}
            />
            
            <div className="font-bold text-[28px] mx-2">Ad Block Recovery </div>
            <AdRecoverBlockTabs
                siteId={siteId}
                startDate={startDate}
                endDate={endDate}
                revenueType={revenueType}
                compare={compare}
                compare_start_date={compare === false ? '' : compare_start_date}
                compare_end_date={compare === false ? '' : compare_end_date}
            />
            
            <div className="font-bold text-[28px] mx-2">Quick Shop </div>
            <QuickShopTabs
                siteId={siteId}
                startDate={startDate}
                endDate={endDate}
                revenueType={revenueType}
                compare={compare}
                compare_start_date={compare === false ? '' : compare_start_date}
                compare_end_date={compare === false ? '' : compare_end_date}
            /> */}
        </div>
    );
};

export default DashboardOverview;
