export const HEADERMENU_PATH = {
    login: '/login',
    dashboard: '/dashboard',
    newDashboard: '/newDashboard',
    adOptimization: '/adoptimization',
    subscriptions: '/subscriptions',
    crowdFunding: '/crowdfunding',
    quickShop: '/quickShop',
    adBlockRecovery: '/adBlockRecovery',
    demandPartners: '/demandPartners',

    setup: '/setup',
    setupAdBlockRecovery: '/setupAdBlockRecovery',
    networkUpload:'/networkUpload',
    prebidUpload: '/prebidUpload',
    networkSettings:'/networkSettings',
    presetsComparison: '/presetsComparison',

    onboarding:'/onboarding',
    reports:'/reports',
    analytics:'/analytics'
};
