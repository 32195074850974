import React, { useState } from 'react';
import AdOptimizationSiteDashboard from './components/SiteDetails/Dashboard';
import ClientRealTimeData from './components/SiteDetails/RealTime';

type Props = {
    siteId: string;
    startDate: string;
    endDate: string;
    revenueType: string;
    compaison?: any;
    compare: boolean;
    compare_start_date: string;
    compare_end_date: string;
    adServer?: boolean;
    applyDate?:string;
};

const AdOptimizationSiteDetail: React.FC<Props> = ({ siteId, startDate, endDate, revenueType, compaison, compare, compare_start_date, compare_end_date, applyDate, adServer }) => {
    const [page, setPage] = useState(true);
    const changeView = ( ) => {
        setPage(!page);
    }
    return (
        <div className="mt-4">
            <div>{page ? 
                <AdOptimizationSiteDashboard 
                    siteId={siteId}
                    startDate={startDate}
                    endDate={endDate}
                    revenueType={revenueType}
                    compaison={compaison}
                    compare={compare}
                    compare_start_date={compare_start_date}
                    compare_end_date={compare_end_date}
                    adServer={adServer ? 'ON' : 'OFF'}
                    applyDate={applyDate}
                    changeView={changeView}/> 
                : 
                <ClientRealTimeData 
                    siteId={siteId}
                    revenueType={revenueType}
                    changeView={changeView}/>
                }
            </div>
        </div>
    );
};

export default AdOptimizationSiteDetail;
