import React from 'react';
import AdBlockRecoveryCharts from './components/dashboard/AdBlockRecoveryCharts';
import AdBlockRecoveryTables from './components/dashboard/AdBlockRecoveryTables';
import InfoGraph from './components/dashboard/InfoGraph';
import AdBlockRecoveryTopCards from './components/dashboard/topCardsAdBlockRecovery';

export const API_date_format = 'YYYY/MM/DD';
type Props = {
    siteId: any;
    startDate: any;
    endDate: any;
    compaison?: any;
    compare?: any;
    compare_start_date?: any;
    compare_end_date?: any;
    revenueType?: any;
};

const AdBlockRecoveryDashboard: React.FC<Props> = ({ siteId, startDate, endDate, compaison, compare, compare_start_date, compare_end_date, revenueType}) => {
    
    return (
        <div className="dashboard">
            <AdBlockRecoveryTopCards
                startDate={startDate}
                endDate={endDate}
                revenueType={revenueType}
                comparison={compaison}
                compare={compare}
                compare_start_date={compare ? compare_start_date : ''}
                compare_end_date={compare ? compare_end_date : ''}
                siteId={null}
            />

            <InfoGraph startDate={startDate} endDate={endDate} siteId={null} />

            <AdBlockRecoveryTables startDate={startDate} endDate={endDate} comparison={compaison} compare={compare} compare_start_date={compare ? compare_start_date : ''} compare_end_date={compare ? compare_end_date : ''} siteId={null} />

            <AdBlockRecoveryCharts
                startDate={startDate}
                endDate={endDate}
                revenueType={revenueType}
                compare={compaison}
                compare_start_date={compaison === false ? '' : compare_start_date}
                compare_end_date={compaison === false ? '' : compare_end_date}
                siteId={null}
            />
        </div>
    );
};

export default AdBlockRecoveryDashboard;
