import React from 'react'
type Props = {
     color: string;
}
const SubscriptionSvgFile: React.FC<Props> = ({ color }) => {
     return (
          <div className='mr-[10px]'>
               <svg width="21px" height="21px" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.329 3.66414H13.2891L12.168 4.72505C11.8776 4.99989 11.878 5.44502 12.169 5.71949C12.4593 5.99324 12.9307 5.99379 13.2214 5.71858L15.6089 3.45924C15.9034 3.18074 15.8995 2.72316 15.5919 2.4496L13.2214 0.206383C12.9311 -0.0684582 12.4599 -0.0688243 12.169 0.205467C11.878 0.479942 11.8776 0.92507 12.168 1.19991L13.286 2.25789C9.91325 2.25789 10.3338 2.25771 10.297 2.25771C2.42875 2.25771 -2.56569 10.3475 1.37978 16.8499C1.58407 17.1866 2.03859 17.3035 2.39561 17.1103C2.75224 16.9171 2.87552 16.4875 2.67123 16.1506C-0.724367 10.5546 3.62212 3.63228 10.329 3.66414Z" fill={color} />
                    <path d="M19.235 7.15002C19.0307 6.81329 18.5758 6.69665 18.2192 6.88964C17.8626 7.08282 17.7393 7.51256 17.9438 7.84948C21.3136 13.4031 17.0605 20.336 10.3077 20.336H7.32574L8.44682 19.2751C8.73736 19.0002 8.73697 18.5551 8.44604 18.2806C8.15511 18.0063 7.68373 18.0067 7.39339 18.2815L5.00587 20.5409C4.71397 20.817 4.71242 21.2753 5.02389 21.5514L7.39339 23.7937C7.68373 24.0684 8.15492 24.0691 8.44604 23.7946C8.73697 23.5202 8.73736 23.075 8.44701 22.8002L7.32904 21.7422H10.317C18.1894 21.742 23.1797 13.6512 19.235 7.15002Z" fill={color} />
                    <path d="M9.56292 9.89062H12.5401C12.951 9.89062 13.2843 9.57568 13.2843 9.1875C13.2843 8.79913 12.951 8.48437 12.5401 8.48437H11.0515V7.78125C11.0515 7.39288 10.7181 7.07812 10.3072 7.07812C9.89611 7.07812 9.56292 7.39288 9.56292 7.78125V8.48437C8.33156 8.48437 7.33008 9.43066 7.33008 10.5937C7.33008 11.7568 8.33156 12.7031 9.56292 12.7031H11.0513C11.4618 12.7031 11.7958 13.0184 11.7958 13.4062C11.7958 13.7941 11.4638 14.1095 11.0513 14.1095C10.2822 14.1095 10.3072 14.1094 10.3072 14.1094C10.306 14.1094 10.3049 14.1095 10.3037 14.1095C10.2748 14.1095 8.05769 14.1094 8.07436 14.1094C7.66326 14.1094 7.33008 14.4241 7.33008 14.8123C7.32988 15.2007 7.66307 15.5154 8.07417 15.5156H9.56292V16.2187C9.56292 16.6069 9.89611 16.9219 10.3072 16.9219C10.7181 16.9219 11.0515 16.6069 11.0515 16.2187V15.5158C12.2854 15.5158 13.2843 14.5726 13.2843 13.4062C13.2843 12.2432 12.2825 11.2969 11.0513 11.2969H9.56292C9.15241 11.2969 8.81864 10.9814 8.81864 10.5937C8.81864 10.2059 9.15241 9.89062 9.56292 9.89062Z" fill={color} />
               </svg>

          </div>
     )
}

export default SubscriptionSvgFile