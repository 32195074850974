import moment from "moment";
import React, { useMemo, useState } from "react";
import DateFilter from "../newDashboard/AdOptimization/components/DateFilter";
import AnalyticsFilter from "./components/AnalyticsFilter";
import AnalyticsTable from "./components/tables/AnalyticsTable";
import { AnalyticsQuery } from "./redux/types";
export const API_date_format = 'YYYY/MM/DD';

const Analytics: React.FC = () => {
     const [applyDate, setApplyDate] = useState('last7Day');
     const [customEndDate, setCustomEndDate] = useState<string | undefined>(undefined);
     const [customStartDate, setCustomStartDate] = useState<string | undefined>(undefined);
     const [compare, setCompare] = useState(false);
     const [dstring2, setDatestring2] = useState({ sdate: '', edate: '' });
     const [setFilterQuery, filterQuery] =useState<AnalyticsQuery>({
          puid:'',
          siteName:'',
          pageVisited:'',
          pageCategory:'',
          timeStamp:'',
          subscriberEmail:'',
          crowdfundingEmail:'',
          adBlock:''
     })
     const endDate = useMemo(() => {
          switch (applyDate) {
               case 'last1Day':
               case 'last7Day':
               case 'last30Day':
                    return moment().startOf('day').subtract(1, 'day').format(API_date_format);
               default:
                    return customEndDate;
          }
     }, [applyDate, customEndDate]);

     const startDate = useMemo(() => {
          switch (applyDate) {
               case 'last1Day':
                    return moment().startOf('day').subtract(1, 'day').format(API_date_format);
               case 'last7Day':
                    return moment().subtract(7, 'day').format(API_date_format);
               case 'last30Day':
                    return moment().subtract(30, 'day').format(API_date_format);
               default:
                    return customStartDate;
          }
     }, [applyDate, customStartDate]);
     return (
          <div className="">
               <div className="flex justify-between h-[60px] relative">
                    <div className="font-[600] font-[Roboto] text-2xl pl-5">Analytics</div>
                    <DateFilter
                         applyDate={applyDate}
                         setApplyDate={setApplyDate}
                         setCustomEndDate={setCustomEndDate}
                         setCustomStartDate={setCustomStartDate}
                         setDatestring2={setDatestring2}
                         dstring2={dstring2}
                         compare={compare}
                         setCompare={setCompare}
                         showCompare={false}
                    />
               </div>
               <div className="flex justify-end border-b-[1px] border-black mt-2">
                    <AnalyticsFilter setFilterQuery={setFilterQuery} filterQuery={filterQuery}/>
               </div>
               <div className="mt-2">
                    <AnalyticsTable/>
               </div>
          </div>
     )
}

export default Analytics