import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { HEADERMENU_PATH } from '../../../../../../routes/RoutesURL';
import AdOptDashboardAction from '../../redux/actions';
import Apis from '../../../../../../api';
import download from 'downloadjs';
import MessageActions from '../../../../../message/redux/actions';
import { BiArrowBack } from 'react-icons/bi';
import PButton from '../../../../../common/Button';
import Networks from '../demandChannel/Networks';
import AdOptRevImp from '../revImp/AdOptRevImp';
import { RootState } from '../../../../../../store/RootReducer';

type Props = {
    siteId?: any;
    startDate?: any;
    endDate?: any;
    compaison?: any;
    compare?: any;
    compare_start_date?: any;
    compare_end_date?: any;
    revenueType?: any;
    applyDate?: any;
    adServer?: boolean;
};

const DemandchannelDetails: React.FC<Props> = ({ siteId, startDate, endDate, compaison, compare, compare_start_date, compare_end_date, revenueType, applyDate, adServer }) => {
    const location = useLocation();
    const networkName = location.state?.network_name;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [pageNumber, setPageNumber] = useState(1);
    const [fileLoading, setLoading] = useState(false);
    const [dates, setDates] = useState<{
        startDate?: string;
        endDate?: string;
        revenue?: string;
        compare?: boolean;
        compare_start_date?: string;
        compare_end_date?: string;
        // search?: string;
        pageNumber: number;
        time_interval?: string;
        ad_server: string;
    }>({
        startDate: startDate,
        endDate: endDate,
        revenue: undefined,
        compare: undefined,
        compare_start_date: undefined,
        compare_end_date: undefined,
        // search: undefined,
        pageNumber: 0,
        time_interval: applyDate,
        ad_server: adServer ? 'ON' : 'OFF',
    });
    const revImpGraph = useSelector((state: RootState) => state.adOptDashboard.revImpGraphData);
    const revImpGraphLoader = useSelector((state: RootState) => state.adOptDashboard.revImpGraphDataLoader);

    const onClickBack = () => {
        navigate(`${HEADERMENU_PATH.newDashboard}${HEADERMENU_PATH.adOptimization}`);
    };

    useEffect(() => {
        if (startDate && endDate) {
            if (
                (dates.startDate !== startDate ||
                    dates.endDate !== endDate ||
                    (compare && (dates.compare_start_date !== compare_start_date || dates.compare_end_date !== compare_end_date)) ||
                    dates.pageNumber !== pageNumber ||
                    (dates.ad_server === 'ON' ? true : false) !== adServer)
            ) {
                setDates({
                    ...dates,
                    endDate: endDate,
                    startDate: startDate,
                    compare: compare,
                    compare_start_date: compare_start_date,
                    compare_end_date: compare_end_date,
                    pageNumber: pageNumber,
                    ad_server: adServer ? 'ON' : 'OFF',
                });
                dispatch(
                    AdOptDashboardAction.fetchSitesTableByNetwork(
                        {
                            start_date: startDate,
                            end_date: endDate,
                            revenue: revenueType,
                            time_interval: applyDate === 'last1Day' || applyDate === 'last7Day' || applyDate === 'last30Day' ? applyDate + 's' : 'customDates',
                            compare: compare,
                            compare_start_date: compare ? compare_start_date : '',
                            compare_end_date: compare ? compare_end_date : '',
                            // search_site: search,
                            ad_server: adServer ? 'ON' : 'OFF',
                            network_id: location.state?.networkId
                        },
                        pageNumber,
                    ),
                );
            }
        }
    }, [dispatch, startDate, endDate, compare, compare_start_date, compare_end_date, pageNumber, applyDate, adServer, location.state?.networkId]);


    useEffect(() => {

        if (startDate && endDate) {
            dispatch(
                AdOptDashboardAction.fetchAdOptRevImpGraph({
                    "start_date": startDate,
                    "end_date": endDate,
                    revenue: revenueType,
                    time_interval: applyDate === 'last1Day' || applyDate === 'last7Day' || applyDate === 'last30Day' ? applyDate + 's' : 'customDates',
                    ad_server: adServer ? 'ON' : 'OFF',
                    network_id: location.state?.networkId,
                    site_id: siteId
                })
            )
        }
    }, [dispatch, siteId, startDate, endDate, revenueType, adServer])

    const handleExport = () => {
        if (startDate && endDate) {
            const interval = applyDate === 'last1Day' ? 'last1days' : applyDate === 'last7Day' ? 'last7days' : applyDate === 'last30Day' ? 'last30days' : 'customDates';
            const data = {
                start_date: `${startDate}`,
                end_date: `${endDate}`,
                table_type: 'all',
                search_site: '',
                compare: compare,
                revenue: `${revenueType}`,
                time_interval: `${interval}`,
                compare_start_date: `${compare_start_date}`,
                compare_end_date: `${compare_end_date}`,
                ad_server: adServer ? 'ON' : 'OFF',
                network_id: location.state?.networkId
            };
            setLoading(true);

            Apis.fetchSitesByNewtorkExportTable(data)
                .then(({ data }) => {
                    download(data, `${moment().format('YYYY/MM/DD')}_Demand_partner_${networkName}.xlsx`);
                })
                .catch((err) => {
                    dispatch(MessageActions.showMessage({ text: String('Something went wrong'), error: true }));
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    return (
        <div>
            <div className="flex  -mt-8">
                <div onClick={onClickBack} className="bg-[#B4D0C133] cursor-pointer h-10 flex items-center px-4 rounded-lg mr-2 mt-12">
                    <BiArrowBack className="mr-2" />
                    <span>Back</span>
                </div>
                <div onClick={onClickBack} className=" cursor-pointer h-10 flex items-center px-4 rounded-lg mr-2 mt-12">
                    <div className="font-bold">
                        <h2 className="text-[18px]">{networkName}</h2>
                    </div>
                </div>

            </div>

            <AdOptRevImp data={revImpGraph} loader={revImpGraphLoader} />
            <div className="flex flex-row-reverse ">
                <PButton loading={fileLoading} className="top-1 mr-2" title={'Export'} onClick={handleExport} />
            </div>
            <Networks compaison={compaison} setPageNumber={setPageNumber} pageNumber={pageNumber} revenueType={revenueType} ad_server={dates?.ad_server} />
        </div>
    );
};

export default DemandchannelDetails;
