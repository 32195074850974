import React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import DemandChannel from './components/dashboard/DemandChannel';
import FavoritesAndRecent from './components/dashboard/favoritesAndRecent';
import AdOptimizationTopCards from './components/dashboard/topCards';
import TopTrends from './components/dashboard/TopTrends';
import SitesListOfAdOptimisation from './ListOfSites';
import AdOptimizationSiteDetail from './SiteDetails';
import DemandchannelDetails from './components/dashboard/DemandChannelDetails';

export const API_date_format = 'YYYY/MM/DD';
type Props = {
    siteId?: any;
    startDate?: any;
    endDate?: any;
    compaison?: any;
    compare?: any;
    compare_start_date?: any;
    compare_end_date?: any;
    revenueType?: any;
    applyDate?: any;
    adServer?: boolean;
    updateSite: ((siteId: string) => void);
};

const AdOptimizationDashboard: React.FC<Props> = ({ siteId, startDate, endDate, compaison, compare, compare_start_date, compare_end_date, revenueType, applyDate, adServer, updateSite }) => {
    const [searchParams] = useSearchParams();
    const location = useLocation();
    // Loading based on revenue monetize & cpm
    if (searchParams.get('key') === 'revenue' || searchParams.get('key') === 'monetizedimpression' || searchParams.get('key') === 'adrequest' || searchParams.get('key') === 'cpm' || searchParams.get('key') === 'fetchTopALl') {
        if (siteId !== 0) {
            return <AdOptimizationSiteDetail
                siteId={siteId}
                startDate={startDate}
                endDate={endDate}
                revenueType={revenueType}
                compaison={compaison}
                compare={compare}
                compare_start_date={compare_start_date}
                compare_end_date={compare_end_date}
                adServer={adServer}
                applyDate={applyDate} />;
        }
        return <SitesListOfAdOptimisation
            siteId={siteId}
            startDate={startDate}
            endDate={endDate}
            revenueType={revenueType}
            compaison={compaison}
            compare={compare}
            compare_start_date={compare_start_date}
            compare_end_date={compare_end_date}
            adServer={adServer}
            applyDate={applyDate} />;
    }

    // Loading site data here
    if (location?.state?.siteId != null) {
        if (siteId === 0)
            updateSite(location?.state?.siteId);

        return <AdOptimizationSiteDetail
            siteId={siteId}
            startDate={startDate}
            endDate={endDate}
            revenueType={revenueType}
            compaison={compaison}
            compare={compare}
            compare_start_date={compare_start_date}
            compare_end_date={compare_end_date}
            adServer={adServer}
            applyDate={applyDate} />;
    }

    // Loading Network data here
    if (location?.state?.networkId != null) {
        return <DemandchannelDetails
            siteId={location?.state?.siteId}
            startDate={startDate}
            endDate={endDate}
            revenueType={revenueType}
            compaison={compaison}
            compare={compare}
            compare_start_date={compare_start_date}
            compare_end_date={compare_end_date}
            adServer={adServer}
            applyDate={applyDate} />;
    }
    return (
        <div className="dashboard ">
            <AdOptimizationTopCards
                siteId={siteId}
                startDate={startDate}
                endDate={endDate}
                revenueType={revenueType}
                comparison={compaison}
                compare={compare}
                compare_start_date={compare_start_date}
                compare_end_date={compare_end_date}
                ad_server={adServer ? 'ON' : 'OFF'}
                applyDate={applyDate}
            />
            {siteId === 0 &&
                <FavoritesAndRecent
                    startDate={startDate}
                    endDate={endDate}
                    revenueType={revenueType}
                    compare={compare}
                    compare_start_date={compare_start_date}
                    compare_end_date={compare_end_date}
                    ad_server={adServer ? 'ON' : 'OFF'}
                />
            }
            {/* <AdOptimizationCharts
                siteId={siteId}
                startDate={startDate}
                endDate={endDate}
                revenueType={revenueType}
                compare={compare}
                compare_start_date={compare_start_date}
                compare_end_date={compare_end_date}
                ad_server={adServer}
            />

          */}

            <DemandChannel
                siteId={siteId}
                startDate={startDate}
                endDate={endDate}
                revenueType={revenueType}
                compare={compare}
                comparison={compaison}
                compare_start_date={compare_start_date}
                compare_end_date={compare_end_date}
                ad_server={adServer ? 'ON' : 'OFF'}
            />
            {
                (siteId === 0 || siteId === "" || !siteId) &&
                <TopTrends
                    startDate={startDate}
                    endDate={endDate}
                    revenueType={revenueType}
                    comparison={compaison}
                    compare={compare}
                    compare_start_date={compare_start_date}
                    compare_end_date={compare_end_date}
                    ad_server={adServer ? 'ON' : 'OFF'}
                />
            }
        </div>
    );
};

export default AdOptimizationDashboard;
