import React, { useEffect,useState } from 'react';
import PTabs from '../../../common/Tabs';
import { BiArrowBack } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { HEADERMENU_PATH } from '../../../../routes/RoutesURL';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import AdOptDashboardAction from './redux/actions';
import Sites from './components/sitesList/Sites';
import PButton from '../../../common/Button';
import Apis from '../../../../api';
import download from 'downloadjs';
import MessageActions from '../../../message/redux/actions';

type Props = {
    siteId: string;
    startDate?: string;
    endDate?: string;
    revenueType?: string;
    compaison?: any;
    compare?: boolean;
    compare_start_date?: string;
    compare_end_date?: string;
    adServer?: boolean;
    applyDate?:string;
};

const SitesListOfAdOptimisation:  React.FC<Props> = ({ siteId, startDate, endDate, revenueType, compaison, compare, compare_start_date, compare_end_date, applyDate, adServer }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState('all');
    const [pageNumber, setPageNumber] = useState(1);
    const [fileLoading, setLoading] = useState(false);
    
    const [dates, setDates] = useState<{
        all: {
            siteId?: string;
            startDate?: string;
            endDate?: string;
            revenue?: string;
            compare?: boolean;
            compare_start_date?: string;
            compare_end_date?: string;
            pageNumber: number;
            time_interval?: string;
            ad_server: string;
        };
        recent: {
            siteId?: string;
            startDate?: string;
            endDate?: string;
            revenue?: string;
            compare?: boolean;
            compare_start_date?: string;
            compare_end_date?: string;
            pageNumber: number;
            ad_server?: string;
        };
        favourite: {
            siteId?: string;
            startDate?: string;
            endDate?: string;
            revenue?: string;
            compare?: boolean;
            compare_start_date?: string;
            compare_end_date?: string;
            pageNumber: number;
            ad_server?: string;
        };
    }>({
        all: {
            siteId: undefined,
            startDate: undefined,
            endDate: undefined,
            revenue: undefined,
            compare: undefined,
            compare_start_date: undefined,
            compare_end_date: undefined,
            pageNumber: 0,
            time_interval: applyDate,
            ad_server: adServer ? 'ON' : 'OFF',
        },
        recent: {
            siteId: undefined,
            startDate: undefined,
            endDate: undefined,
            revenue: undefined,
            compare: undefined,
            compare_start_date: undefined,
            compare_end_date: undefined,
            pageNumber: 0,
            ad_server: adServer ? 'ON' : 'OFF',
        },
        favourite: {
            siteId: undefined,
            startDate: undefined,
            endDate: undefined,
            revenue: undefined,
            compare: undefined,
            compare_start_date: undefined,
            compare_end_date: undefined,
            pageNumber: 0,
            ad_server: adServer ? 'ON' : 'OFF',
        },
    });

    const onTabChange = (tab: string) => {
        setPageNumber(tab === 'all' ? (dates.all.pageNumber === 0 ? 1 : dates.all.pageNumber) : tab === 'favorites' ? (dates.favourite.pageNumber === 0 ? 1 : dates.favourite.pageNumber) : dates.recent.pageNumber === 0 ? 1 : dates.recent.pageNumber);
        setActiveTab(tab);
    };

    const onClickBack = () => {
        navigate(`${HEADERMENU_PATH.newDashboard}${HEADERMENU_PATH.adOptimization}`);
    };

    useEffect(() => {
        if (startDate && endDate) {
            if (
                activeTab === 'all' &&
                (dates.all.startDate !== startDate || dates.all.endDate !== endDate || dates.all.siteId !== siteId || 
                    (compare && (dates.all.compare_start_date !== compare_start_date || dates.all.compare_end_date !== compare_end_date))
                    || dates.all.pageNumber !== pageNumber 
                    || (dates.all.ad_server === 'ON' ? true : false) !== adServer
                )
            ) {
                setDates({
                    ...dates,
                    all: {
                        siteId: siteId,
                        endDate: endDate,
                        startDate: startDate,
                        compare: compare,
                        compare_start_date: compare_start_date,
                        compare_end_date: compare_end_date,
                        pageNumber: pageNumber,
                        ad_server: adServer ? 'ON' : 'OFF',
                    },
                });
                dispatch(
                    AdOptDashboardAction.fetchAllSitesOfList(
                        {
                            start_date: startDate,
                            end_date: endDate,
                            time_interval: applyDate === 'last1Day' || applyDate === 'last7Day' || applyDate === 'last30Day' ? applyDate + 's' : 'customDates',
                            compare: compare,
                            compare_start_date: compare ? compare_start_date : '',
                            compare_end_date: compare ? compare_end_date : '',
                            ad_server: adServer ? 'ON' : 'OFF',
                            site_id: siteId,
                        },
                        pageNumber,
                    ),
                );
            }
            if (
                activeTab === 'favorites' &&
                (dates.favourite.startDate !== startDate ||
                    dates.favourite.endDate !== endDate || 
                    dates.favourite.siteId !== siteId ||
                    (compare && (dates.favourite.compare_start_date !== compare_start_date || dates.favourite.compare_end_date !== compare_end_date)) ||
                    dates.favourite.pageNumber !== pageNumber
                    // || (dates.favourite.ad_server === 'ON' ? true : false) !== adServer 
                )
            ) {
                setDates({
                    ...dates,
                    favourite: {
                        siteId: siteId,
                        endDate: endDate,
                        startDate: startDate,
                        compare: compare,
                        compare_start_date: compare_start_date,
                        compare_end_date: compare_end_date,
                        pageNumber: pageNumber,
                        ad_server: adServer ? 'ON' : 'OFF',
                    },
                });
                dispatch(
                    AdOptDashboardAction.fetchFavoriteSites(
                        {
                            start_date: startDate,
                            end_date: endDate,
                            compare: compare,
                            compare_start_date: compare ? compare_start_date : '',
                            compare_end_date: compare ? compare_end_date : '',
                            ad_server: adServer ? 'ON' : 'OFF',
                            site_id: siteId,
                        },
                        pageNumber,
                    ),
                );
            }
        }
    }, [dispatch, startDate, endDate, activeTab, dates, compare, compare_start_date, compare_end_date, pageNumber, applyDate, adServer, siteId]);

    useEffect(() => {
        if (startDate && endDate) {
            if (
                activeTab === 'recent' &&
                (dates.recent.startDate !== startDate ||
                    dates.recent.endDate !== endDate ||
                    dates.recent.siteId !== siteId ||
                    (compare && (dates.recent.compare_start_date !== compare_start_date || dates.recent.compare_end_date !== compare_end_date))
                    // || (dates.recent.ad_server === 'ON' ? true : false) !== adServer
                )
            ) {
                setDates({
                    ...dates,
                    recent: {
                        siteId: siteId,
                        endDate: endDate,
                        startDate: startDate,
                        compare: compare,
                        compare_start_date: compare_start_date,
                        pageNumber: 0,
                        compare_end_date: compare_end_date,
                        ad_server: adServer ? 'ON' : 'OFF',
                    },
                });
                dispatch(
                    AdOptDashboardAction.fetchRecentSites({
                        start_date: startDate,
                        end_date: endDate,
                        compare: compare,
                        compare_start_date: compare ? compare_start_date : '',
                        compare_end_date: compare ? compare_end_date : '',
                        ad_server: adServer ? 'ON' : 'OFF',
                        site_id: siteId,
                    }),
                );
            }
        }
    }, [dispatch, startDate, endDate, activeTab, dates, compare, compare_start_date, compare_end_date, adServer, siteId]);

    const handleExport = () => {
        if (startDate && endDate) {
            const interval = applyDate === 'last1Day' ? 'last1days' : applyDate === 'last7Day' ? 'last7days' : applyDate === 'last30Day' ? 'last30days' : 'customDates';
            const data = {
                start_date: `${startDate}`,
                end_date: `${endDate}`,
                table_type: `${activeTab}`,
                search_site: '',
                compare: compare,
                revenue: `${revenueType}`,
                time_interval: `${interval}`,
                compare_start_date: `${compare_start_date}`,
                compare_end_date: `${compare_end_date}`,
                ad_server: adServer ? 'ON' : 'OFF',
                site_id: siteId,
            };
            setLoading(true);

            Apis.fetchSitesExportTable(data)
                .then(({ data }) => {
                    download(data, `${moment().format('YYYY/MM/DD')}_${activeTab}.xlsx`);
                })
                .catch((err) => {
                    dispatch(MessageActions.showMessage({ text: String('Something went wrong'), error: true }));
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    return (
        <div>
            <div className="w-[100%] relative flex flex-row-reverse -mt-8">
                <PButton loading={fileLoading} className="top-12" title={'Export'} onClick={handleExport} />
                <div onClick={onClickBack} className="bg-[#B4D0C133] cursor-pointer h-10 flex items-center px-4 rounded-lg mr-2 mt-12">
                    <BiArrowBack className="mr-2" />
                    <span>Back</span>
                </div>
            </div>
            <PTabs
                className={'flex'}
                activeTab={activeTab}
                setActiveTab={onTabChange}
                tabs={[
                    { key: 'all', title: 'All' },
                    { key: 'favorites', title: 'Favorites' },
                    { key: 'recent', title: 'Recent' },
                ]}
            />
            <Sites activeTab={activeTab} compaison={compaison} setPageNumber={setPageNumber} pageNumber={pageNumber} revenueType={revenueType} ad_server={activeTab === 'all' ? dates?.all?.ad_server : activeTab === 'favorites' ? dates?.favourite?.ad_server : dates?.recent?.ad_server} />
        </div>
    );
};

export default SitesListOfAdOptimisation;
