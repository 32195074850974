import React from 'react';

export interface TabView {
    key: string;
    title: string;
    accessDenied?: boolean;
    icon?: any;
}

type Props = {
    tabs: TabView[];
    activeTab: string;
    setActiveTab: (args: string) => void;
    className?: string;
};

const PTabs: React.FC<Props> = ({ tabs, activeTab, setActiveTab, className }) => {
    return (
        <div id="tabs" className={className === undefined ? "pt-4 w-full" : className }>
            <div className="w-full">
                <div className="flex right-auto top-8 bottom-0 mx-auto overflow-auto border-black border-b">
                    {tabs.map((item) => (
                        <div key={item.title} className={!item.accessDenied ? 'mr-6 lg:mr-12' : ''}>
                            {!item.accessDenied && (
                                <div onClick={() => setActiveTab(item.key)} className="relative cursor-pointer w-max">
                                    {item.icon ? (
                                        <p className={item.key === activeTab ? 'flex flex-wrap justify-between font-medium activeTab roboto-medium mb-2 text-base' : 'flex flex-wrap justify-between font-normal text-light-grey roboto mb-2 text-base'}>{item.icon} {item.title}</p>
                                    ) : (
                                        <p className={item.key === activeTab ? 'font-medium activeTab roboto-medium mb-2 text-base' : 'font-normal text-light-grey roboto mb-2 text-base'}>{item.title}</p> 
                                    )}
                                    {item.key === activeTab && <div className="down-tab-bar" />}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PTabs;
