import React from 'react';
import { useSearchParams } from 'react-router-dom';
import SubscriptionsTopCards from './components/dashboard/topCardsSubscriptions';
import ReasonForUnsubscribing from './components/dashboard/ReasonForUnsubscribing';
import SubscriptionsTables from './components/dashboard/SubscriptionTables';
import SubscriptionsCharts from './components/dashboard/Charts/SubscriptionCharts/SubscriptionsCharts';
import BreakDown from './components/dashboard/BreakDown';
import CountryDeviceSubscription from './components/dashboard/Charts/components/CountryDeviceSubscription';
import TopCardDetails from './components/TopCardDetails/TopCardDetails';
import SubsUnsubsDetail from './components/TopCardDetails/SubsUnsubsDetail';
export const API_date_format = 'YYYY/MM/DD';

type Props = {
    siteId: any;
    startDate: any;
    endDate: any;
    compaison?: any;
    compare?: any;
    compare_start_date?: any;
    compare_end_date?: any;
    revenueType?: any;
};

const SubscriptionsDashboard: React.FC<Props> = ({ siteId, startDate, endDate, compaison, compare, compare_start_date, compare_end_date, revenueType }) => {
    const [searchParams] = useSearchParams();

    if (searchParams.get('key') === 'Active Subscriptions') {
        return <TopCardDetails
            name={searchParams.get('key') === 'Active Subscriptions' ? "activeSubs" : searchParams.get('key') === 'New Subs' ? "newSubs" : searchParams.get('key') === 'UnSubs' ? "unSubs" : ""}
            siteId={siteId}
            startDate={startDate}
            endDate={endDate}
            compare={compare}
            compare_start_date={compare_start_date}
            compare_end_date={compare_end_date}
        />;
    }
    else if (searchParams.get('key') === 'New Subs' || searchParams.get('key') === 'UnSubs')
        return <SubsUnsubsDetail name={searchParams.get('key') === 'New Subs' ? "newSubs" : "unSubs"} siteId={siteId} startDate={startDate} endDate={endDate} />

    return (
        <div className="dashboard">
            <SubscriptionsTopCards
                startDate={startDate}
                endDate={endDate}
                revenueType={revenueType}
                comparison={compaison}
                compare={compare}
                compare_start_date={compare_start_date}
                compare_end_date={compare_end_date}
                siteId={siteId !== 0 ? siteId : null}
            />
            <CountryDeviceSubscription startDate={startDate} endDate={endDate} siteId={siteId !== 0 ? siteId : null} />
            <ReasonForUnsubscribing startDate={startDate} endDate={endDate} siteId={siteId !== 0 ? siteId : null} />
            <SubscriptionsTables
                startDate={startDate}
                endDate={endDate}
                revenueType={revenueType}
                compare={compare}
                comparison={compaison}
                compare_start_date={compare_start_date}
                compare_end_date={compare_end_date}
                siteId={siteId !== 0 ? siteId : null}
            />
            <SubscriptionsCharts
                startDate={startDate}
                endDate={endDate}
                revenueType={revenueType}
                compare={compare}
                compare_start_date={compare_start_date}
                compare_end_date={compare_end_date}
                siteId={siteId !== 0 ? siteId : null}
            />

            <BreakDown comparison={compaison} siteId={siteId !== 0 ? siteId : null} />
        </div>
    );
};

export default SubscriptionsDashboard;
