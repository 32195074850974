import React, { useEffect, useMemo, useState } from 'react';
import PTable from '../../../../../common/Table';
import { commaSeperator } from '../../../../../../utils/Validation';
import { DateTable, FavoriteOrRecent } from '../../../AdOptimization/redux/types';
import { GoPrimitiveDot } from 'react-icons/go';
import { useNavigate } from 'react-router-dom';
import { HEADERMENU_PATH } from '../../../../../../routes/RoutesURL';
import { BiArrowBack } from 'react-icons/bi';
import ModalLogs from './ModalLogs';
import ModalName from './ModalName';
import SelectPublisherDropDown from '../dashboard/SelectPublisherDropDown';
import { useDispatch, useSelector } from 'react-redux';
import SubsDashboardAction from '../../redux/actions';
import { RootState } from '../../../../../../store/RootReducer';
import moment from 'moment';
import PNormalInput from '../../../../../common/NormalInput';
import PButton from '../../../../../common/Button';
import download from 'downloadjs';
import Apis from '../../../../../../api';
import MessageActions from '../../../../../message/redux/actions';

type Props = {
    startDate: any;
    endDate: any;
    compare: boolean;
    compare_start_date: any;
    compare_end_date: string;
    siteId: any;
    name: any
};
const TopCardDetails = ({ siteId, startDate, endDate, name, compare, compare_end_date, compare_start_date }: Props) => {
    const [selectType, setSelectType] = useState<string | undefined>('active');
    const [searchName, setSearchName] = useState<string | undefined>('');
    const [searchEmail, setSearchEmail] = useState<string | undefined>('');
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [modalKeys, setModalKeys] = useState<FavoriteOrRecent | undefined>(undefined);
    const [logModalKeys, setLogModalKeys] = useState<FavoriteOrRecent | undefined>(undefined);
    const [client, setClient] = useState<string | undefined>(undefined);
    const [searched, setSearched] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const subscriberData = useSelector((state: RootState) => state.subsDashboard.subscriberTable);
    const subscriberLoading = useSelector((state: RootState) => state.subsDashboard.subscriberTableLoader);

    //const subscriberList = useSelector((state: RootState) => state.subsDashboard?.subscriptionNew);
    // const subscriberListLoader = useSelector((state: RootState) => state.subsDashboard.subscribersListLoader);
    let data1 = useMemo(() => {
        setSearched(false);
        let data: any = [];
        if (selectType === 'all' && subscriberData) {
            setClient(undefined);
            return subscriberData;
        } else if (subscriberData && selectType === 'active') {
            setClient(undefined);
            subscriberData.forEach((d: any) => {
                if (d.status === 1) {
                    data.push(d);
                }
            });
        } else {
            setClient(undefined);
            if (subscriberData) {
                subscriberData.forEach((d: any) => {
                    if (d.status === 0) {
                        data.push(d);
                    }
                });
            }
        }
        return data;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectType, subscriberData]);

    const data2 = useMemo(() => {
        let searchingData: any = [];
        // if (client) {
        //     setSelectType(' ');
        //     setSearched(true);
        //     subscriberData.forEach((d: any) => {
        //         if (Number(d.id) === Number(client)) {
        //             searchingData.push(d);
        //         }
        //     })
        // }
        return searchingData?.length > 0 ? searchingData : [];
    }, []);

    let filterData = searched ? data2 : data1;

    useEffect(() => {
        dispatch(SubsDashboardAction.fetchSubscriberList());
    }, [dispatch]);

    useEffect(() => {
        if (name === 'activeSubs' && startDate && endDate) {
            dispatch(
                SubsDashboardAction.fetchSubscriptionSubscriber({
                    start_date: startDate,
                    end_date: endDate,
                    site_id: siteId !== '0' ? siteId : null,
                    name: searchName,
                    email: searchEmail
                }),
            );
        }
    }, [startDate, endDate, siteId, dispatch, searchEmail, searchName]);
    const onClickBack = () => {
        navigate(`${HEADERMENU_PATH.newDashboard}${HEADERMENU_PATH.subscriptions}`);
    };

    const exportData = () => {
        if (startDate && endDate) {
            const data = {
                start_date: startDate,
                end_date: endDate,
                table_type: "subscribers",
                compare: compare,
                compare_start_date: compare_start_date,
                compare_end_date: compare_end_date,
                site_id: siteId,
            };
            setLoading(true);

            Apis.fetchsubscriptionDashboardExportTable(data)
                .then(({ data }) => {
                    download(data, `${moment().format('YYYY/MM/DD')}_subscribers.xlsx`);
                })
                .catch((err) => {
                    dispatch(MessageActions.showMessage({ text: String('Something went wrong'), error: true }));
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const columns = [
        {
            dataIndex: 'site_name',
            title: 'Site',
            width: 250,
            fixed: 'left',
            render: (text: any, row: FavoriteOrRecent, index: number) => {
                return <div className="flex items-center justify-start">{text}</div>;
            },
            sorter: (a: any, b: any) => String(a.site_name).localeCompare(String(b.site_name)),
        },
        {
            dataIndex: 'name',
            title: 'Name',
            render: (text: any, row: FavoriteOrRecent) => (
                <div
                    className="flex items-center justify-start gap-2 text-green-600 underline"
                    onClick={() => {
                        setOpen(true);
                        setModalKeys(row);
                    }}
                >
                    <span>{row?.status === 0 ? <GoPrimitiveDot size={24} color="red" /> : <GoPrimitiveDot size={24} color="green" />}</span>
                    <span>{String(text || ' ')}</span>
                </div>
            ),
            sorter: (a: any, b: any) => String(a.name).localeCompare(String(b.name)),
        },
        {
            dataIndex: 'email',
            title: 'Email',
            render: (text: any, row: FavoriteOrRecent) => (
                <div
                    className="flex items-center justify-start gap-2 text-green-600 underline"
                    onClick={() => {
                        setOpen(true);
                        setModalKeys(row);
                    }}
                >
                    <span>{row?.status === 0 ? <GoPrimitiveDot size={24} color="red" /> : <GoPrimitiveDot size={24} color="green" />}</span>
                    <span>{String(text || ' ')}</span>
                </div>
            ),
            sorter: (a: any, b: any) => String(a.email).localeCompare(String(b.email)),
        },
        {
            dataIndex: 'date',
            title: 'Signed Up',
            width: 250,
            render: (text: any, row: FavoriteOrRecent) => (
                <div className="flex items-center justify-center gap-2">
                    <span>{text}</span>
                </div>
            ),
            sorter: (a: DateTable, b: DateTable) => moment(a.date).unix() - moment(b.date).unix(),
        },
        {
            dataIndex: 'amount',
            title: 'Amount',
            width: 150,
            render: (text: any, row: FavoriteOrRecent) => {
                return (
                    <div className="flex items-center justify-end gap-2">
                        <span>{`$${parseFloat(commaSeperator(text)).toFixed(2)}`}</span>
                    </div>
                );
            },
            sorter: (a: any, b: any) => parseFloat(a.amount) - parseFloat(b.amount),
        },
        {
            dataIndex: 'plan_type',
            title: 'Plan Type',
            width: 150,
            render: (text: any, row: FavoriteOrRecent) => (
                <div className="flex items-center justify-center gap-2">
                    <span>{text}</span>
                </div>
            ),
            sorter: (a: any, b: any) => String(a.plan_type).localeCompare(String(b.plan_type)),
        },
        {
            dataIndex: '',
            title: 'Logs',
            width: 100,
            render: (text: any, row: FavoriteOrRecent) => (
                <div
                    className="flex items-center justify-center gap-2 text-green-600 underline "
                    onClick={() => {
                        setOpen2(true);
                        setLogModalKeys(row);
                    }}
                >
                    <span>{'Logs'}</span>
                </div>
            ),
        },
    ];


    return (
        <div>
            <div className="relative flex justify-between items-center pb-4">
                <div className="flex items-start mt-4">
                    <div onClick={onClickBack} className="bg-[#B4D0C133] cursor-pointer h-10 flex items-center px-4 rounded-lg mr-2 mt-1">
                        <BiArrowBack className="mr-2" />
                        <span>Back</span>
                    </div>
                </div>
                <div className="flex items-center gap-4">

                    <div>
                        <div className="text-[10px] ml-2">Search Name</div>
                        <PNormalInput
                            title=''
                            className='w-[200px] h-[38px]'
                            name='name'
                            onChange={(e: any) => setSearchName(e.value)}
                            value={searchName}
                            placeholder='Search Name'
                        />
                    </div>
                    <div>
                        <div className="text-[10px] ml-2">Search Email</div>
                        <PNormalInput
                            title=''
                            className='w-[200px] h-[38px]'
                            name='email'
                            onChange={(e: any) => setSearchEmail(e.value)}
                            value={searchEmail}
                            placeholder='Search Email'
                        />
                    </div>
                    <SelectPublisherDropDown selectType={selectType} setSelectType={setSelectType} title="Subscription Status" />
                </div>
            </div>

            <div className="w-[100%] relative flex flex-row-reverse">
                <PButton loading={loading} className="mb-2" title={'Export'} onClick={() =>exportData() } />
            </div>
            <PTable className="dashboard_table" columns={columns} data={filterData} loading={subscriberLoading} pagination={{ isShow: true }} rowKey={(d: FavoriteOrRecent) => `${Math.random()}`} />
            <ModalLogs open2={open2} setOpen2={setOpen2} logModalData={logModalKeys} />
            <ModalName open={open} setOpen={setOpen} modalData={modalKeys} />
        </div>
    );
};

export default TopCardDetails;
