import { CommonAction } from '../../../../login/redux/types';
import DashboardOverviewTypes, { DashboardOverviewState } from './types';

const DashboardOverviewInitialState: DashboardOverviewState = {
    favorites: [],
    favoriteLoader: false,
    recentLoader: false,
    recents: [],
    topTrends: [],
    topTrendLoading: false,
    revenueGraph: [],
    revenueGraphLoading: false,
    demandChannel: [],
    demandChannelLoading: false,
    adRequestGraph: [],
    adRequestGraphLoading: false,
    fillRateGraph: [],
    fillRateGraphLoading: false,
    monetizedImpsGraph: [],
    sites: [],
    monetizedImpsGraphLoading: false,
    cpmGraph: [],
    cpmGraphLoading: false,
    allSites: [],
    topAdOptLoader: false,
    topCpmLoader: false,
    favouriteSiteLoader: false,
    recentSiteLoader: false,
    favouriteSite: [],
    recentSite: [],
    allSitesOfList: [],
    allSitesLoading: false,
    count: {
        siteCount: 0,
        fCount: 0,
        rCount: 0,
    },
    FavouriteUnfavourite: undefined,
    FavouriteUnfavouriteLoader: false,
    siteTableData: [],
    siteTableDataLoader: false,
    demandChannelStat: [],
    demandChannelStatLoading: false,
    sizeStat: [],
    sizeStatLoading: false,
    fillUnfillUnrendered: [],
    fillUnfillUnrenderedLoading: false,
    dateTable: [],
    dateTableLoading: false,
    networkTableLoading: false,
    sizeTableLoading: false,
    networkTable: [],
    sizeTable: [],
    dashboardAllSites: [],
    dashboardAllSitesLoading: false,
};

const dashboardOverviewReducer = (state = DashboardOverviewInitialState, action: CommonAction): DashboardOverviewState => {
    switch (action.type) {
        case DashboardOverviewTypes.FETCH_TOP_12_AD_OPT_FAVORITES:
            return {
                ...state,
                favorites: [],
                favoriteLoader: true,
            };
        case DashboardOverviewTypes.SET_TOP_12_AD_OPT_FAVORITES:
            return {
                ...state,
                favorites: action.payload,
                favoriteLoader: false,
            };
        case DashboardOverviewTypes.FETCH_TOP_12_AD_OPT_RECENT:
            return {
                ...state,
                recents: [],
                recentLoader: true,
            };
        case DashboardOverviewTypes.SET_TOP_12_AD_OPT_RECENT:
            return {
                ...state,
                recents: action.payload,
                recentLoader: false,
            };
        case DashboardOverviewTypes.FETCH_SITES_LIST_AD_OPT_SITES:
            return {
                ...state,
                allSitesLoading: true,
                sites: [],
            };
        case DashboardOverviewTypes.SET_SITES_LIST_AD_OPT_ALL_SITES:
            return {
                ...state,
                sites: action.payload,
                allSitesLoading: false,
            };

        case DashboardOverviewTypes.FETCH_AD_OPT_TOP_TREND:
            return {
                ...state,
                topTrends: [],
                topTrendLoading: true,
            };
        case DashboardOverviewTypes.SET_AD_OPT_TOP_TREND:
            return {
                ...state,
                topTrends: action.payload,
                topTrendLoading: false,
            };
        case DashboardOverviewTypes.FETCH_AD_OPT_REVENUE_GRAPH:
            return {
                ...state,
                revenueGraph: [],
                revenueGraphLoading: true,
            };
        case DashboardOverviewTypes.SET_AD_OPT_REVENUE_GRAPH:
            return {
                ...state,
                revenueGraph: action.payload,
                revenueGraphLoading: false,
            };
        case DashboardOverviewTypes.FETCH_AD_OPT_DEMAND_CHANNEL:
            return {
                ...state,
                demandChannel: [],
                demandChannelLoading: true,
            };
        case DashboardOverviewTypes.SET_AD_OPT_DEMAND_CHANNEL:
            return {
                ...state,
                demandChannel: action.payload,
                demandChannelLoading: false,
            };
        case DashboardOverviewTypes.FETCH_AD_OPT_AD_REQUEST_GRAPH:
            return {
                ...state,
                adRequestGraph: [],
                adRequestGraphLoading: true,
            };
        case DashboardOverviewTypes.SET_AD_OPT_AD_REQUEST_GRAPH:
            return {
                ...state,
                adRequestGraph: action.payload,
                adRequestGraphLoading: false,
            };
        case DashboardOverviewTypes.FETCH_AD_OPT_FILLRATE_GRAPH:
            return {
                ...state,
                fillRateGraph: [],
                fillRateGraphLoading: true,
            };
        case DashboardOverviewTypes.SET_AD_OPT_FILLRATE_GRAPH:
            return {
                ...state,
                fillRateGraph: action.payload,
                fillRateGraphLoading: false,
            };
        case DashboardOverviewTypes.FETCH_AD_OPT_MONETIZED_IMPS_GRAPH:
            return {
                ...state,
                monetizedImpsGraph: [],
                monetizedImpsGraphLoading: true,
            };
        case DashboardOverviewTypes.SET_AD_OPT_MONETIZED_IMPS_GRAPH:
            return {
                ...state,
                monetizedImpsGraph: action.payload,
                monetizedImpsGraphLoading: false,
            };
        case DashboardOverviewTypes.FETCH_AD_OPT_CPM_GRAPH:
            return {
                ...state,
                cpmGraph: [],
                cpmGraphLoading: true,
            };
        case DashboardOverviewTypes.SET_AD_OPT_CPM_GRAPH:
            return {
                ...state,
                cpmGraph: action.payload,
                cpmGraphLoading: false,
            };
        case DashboardOverviewTypes.FETCH_TOP_CARD_ADOPT:
            return {
                ...state,
                topAdOpt: undefined,
                topAdOptLoader: true,
            };
        case DashboardOverviewTypes.SET_TOP_CARD_ADOPT:
            return {
                ...state,
                topAdOpt: action.payload,
                topAdOptLoader: false,
            };

        case DashboardOverviewTypes.FETCH_TOP_CARD_REVENUE_CPM:
            return {
                ...state,
                topCpm: undefined,
                topCpmLoader: true,
            };
        case DashboardOverviewTypes.SET_TOP_CARD_REVENUE_CPM:
            return {
                ...state,
                topCpm: action.payload,
                topCpmLoader: false,
            };
        case DashboardOverviewTypes.FETCH_SITES_LIST_AD_OPT_FAVOURITE:
            return {
                ...state,
                favouriteSite: [],
                favouriteSiteLoader: true,
            };
        case DashboardOverviewTypes.SET_SITES_LIST_AD_OPT_FAVOURITE:
            return {
                ...state,
                favouriteSite: action.payload,
                favouriteSiteLoader: false,
            };

        case DashboardOverviewTypes.FETCH_SITES_LIST_AD_OPT_RECENT:
            return {
                ...state,
                recentSite: [],
                recentLoader: true,
            };
        case DashboardOverviewTypes.SET_SITES_LIST_AD_OPT_RECENT:
            return {
                ...state,
                recentSite: action.payload,
                recentLoader: false,
            };
        case DashboardOverviewTypes.FETCH_AD_OPT_All_SITES:
            return {
                ...state,
                allSites: [],
                allSitesLoading: true,
            };
        case DashboardOverviewTypes.SET_AD_OPT_ALL_SITES:
            return {
                ...state,
                allSites: action.payload,
                allSitesLoading: false,
            };
        case DashboardOverviewTypes.SITE_PAGINATION_COUNT:
            return {
                ...state,
                count: action.payload,
            };

        //fav unfav site reducers
        case DashboardOverviewTypes.FETCH_SITES_FAV_UNFAV:
            return {
                ...state,
                FavouriteUnfavourite: undefined,
                FavouriteUnfavouriteLoader: true,
            };
        case DashboardOverviewTypes.SET_SITES_FAV_UNFAV:
            return {
                ...state,
                FavouriteUnfavourite: action.payload,
                FavouriteUnfavouriteLoader: false,
            };
        case DashboardOverviewTypes.FETCH_SITE_AD_OPT_CPM_GRAPH_BY_SITE:
            return {
                ...state,
                CpmGraphBySite: undefined,
                CpmGraphBySiteLoader: true,
            };
        case DashboardOverviewTypes.SET_SITE_AD_OPT_CPM_GRAPH_BY_SITE:
            return {
                ...state,
                CpmGraphBySite: action.payload,
                CpmGraphBySiteLoader: false,
            };
        case DashboardOverviewTypes.FETCH_SITE_AD_OPT_REVENUE_GRAPH_BY_SITE:
            return {
                ...state,
                RevenueGraphBySite: undefined,
                RevenueGraphBySiteLoader: true,
            };
        case DashboardOverviewTypes.SET_SITE_AD_OPT_REVENUE_GRAPH_BY_SITE:
            return {
                ...state,
                RevenueGraphBySite: action.payload,
                RevenueGraphBySiteLoader: false,
            };
        case DashboardOverviewTypes.FETCH_SITE_AD_OPT_REQUEST_GRAPH_BY_SITE:
            return {
                ...state,
                RequestGraphBySite: undefined,
                RequestGraphBySiteLoader: true,
            };
        case DashboardOverviewTypes.SET_SITE_AD_OPT_REQUEST_GRAPH_BY_SITE:
            return {
                ...state,
                RequestGraphBySite: action.payload,
                RequestGraphBySiteLoader: false,
            };

        case DashboardOverviewTypes.SET_SITE_LOCAL:
            return {
                ...state,
                CpmGraphBySite: action.payload,
                CpmGraphBySiteLoader: false,
            };

        //fav unfav site reducers
        case DashboardOverviewTypes.FETCH_SITE_TABLE_DATE:
            return {
                ...state,
                siteTableData: [],
                siteTableDataLoader: true,
            };
        case DashboardOverviewTypes.SET_SITE_TABLE_DATE:
            return {
                ...state,
                siteTableData: action.payload,
                siteTableDataLoader: false,
            };
        case DashboardOverviewTypes.FETCH_SITE_AD_OPT_DEMAND_CHANNEL_STATS:
            return {
                ...state,
                demandChannelStatLoading: true,
                demandChannelStat: [],
            };
        case DashboardOverviewTypes.SET_SITE_AD_OPT_DEMAND_CHANNEL_STATS:
            return {
                ...state,
                demandChannelStatLoading: false,
                demandChannelStat: action.payload,
            };
        case DashboardOverviewTypes.FETCH_SITE_AD_OPT_SIZE_STATS:
            return {
                ...state,
                sizeStatLoading: true,
                sizeStat: [],
            };
        case DashboardOverviewTypes.SET_SITE_AD_OPT_SIZE_STATS:
            return {
                ...state,
                sizeStatLoading: false,
                sizeStat: action.payload,
            };
        case DashboardOverviewTypes.FETCH_SITE_AD_OPT_FILL_UNFILL_LINE_GRAPH:
            return {
                ...state,
                fillUnfillUnrendered: [],
                fillUnfillUnrenderedLoading: true,
            };
        case DashboardOverviewTypes.SET_SITE_AD_OPT_FILL_UNFILL_LINE_GRAPH:
            return {
                ...state,
                fillUnfillUnrendered: action.payload,
                fillUnfillUnrenderedLoading: false,
            };
        case DashboardOverviewTypes.FETCH_SITE_AD_OPT_DATE_TABLE:
            return {
                ...state,
                dateTableLoading: true,
                dateTable: [],
            };
        case DashboardOverviewTypes.SET_SITE_AD_OPT_DATE_TABLE:
            return {
                ...state,
                dateTableLoading: false,
                dateTable: action.payload,
            };
        case DashboardOverviewTypes.FETCH_AD_OPT_NETWORK_TABLE:
            return {
                ...state,
                networkTableLoading: true,
                networkTable: [],
            };
        case DashboardOverviewTypes.SET_AD_OPT_NETWORK_TABLE:
            return {
                ...state,
                networkTableLoading: false,
                networkTable: action.payload,
            };
        case DashboardOverviewTypes.FETCH_AD_OPT_SIZE_TABLE:
            return {
                ...state,
                sizeTableLoading: true,
                sizeTable: [],
            };
        case DashboardOverviewTypes.SET_AD_OPT_SIZE_TABLE:
            return {
                ...state,
                sizeTableLoading: false,
                sizeTable: action.payload,
            };

        //IMPA GRAPH BY SIDTE
        case DashboardOverviewTypes.FETCH_SITE_AD_OPT_IMPS_GRAPH_BY_SITE:
            return {
                ...state,
                ImpsGraphBySite: undefined,
                ImpsGraphBySiteLoader: true,
            };
        case DashboardOverviewTypes.SET_SITE_AD_OPT_IMPS_GRAPH_BY_SITE:
            return {
                ...state,
                ImpsGraphBySite: action.payload,
                ImpsGraphBySiteLoader: false,
            };

        //revreq realtime
        case DashboardOverviewTypes.FETCH_PAGEVIEW_IMPRESSION_GRAPH_REALTIME:
            return {
                ...state,
                pageViewImpressionRealtimeLoader: true,
            };
        case DashboardOverviewTypes.SET_PAGEVIEW_IMPRESSION_GRAPH_REALTIME:
            return {
                ...state,
                pageViewImpressionRealtime: action.payload,
                pageViewImpressionRealtimeLoader: false,
            };
        case DashboardOverviewTypes.FETCH_REVENUE_REQUEST_GRAPH_REALTIME:
            return {
                ...state,
                revReqGraphBySiteRealtimeLoader: true,
            };
        case DashboardOverviewTypes.SET_REVENUE_REQUEST_GRAPH_REALTIME:
            return {
                ...state,
                revReqGraphBySiteRealtime: action.payload,
                revReqGraphBySiteRealtimeLoader: false,
            };
        case DashboardOverviewTypes.FETCH_CPM_GRAPH_SITE_REALTIME:
            return {
                ...state,
                cpmGraphBySiteRealtimeLoader: true,
            };
        case DashboardOverviewTypes.SET_CPM_GRAPH_SITE_REALTIME:
            return {
                ...state,
                cpmGraphBySiteRealtime: action.payload,
                cpmGraphBySiteRealtimeLoader: false,
            };
        case DashboardOverviewTypes.FETCH_RPM_GRAPH_SITE_REALTIME:
            return {
                ...state,
                rpmGraphBySiteRealtimeLoader: true,
            };
        case DashboardOverviewTypes.SET_RPM_GRAPH_SITE_REALTIME:
            return {
                ...state,
                rpmGraphBySiteRealtime: action.payload,
                rpmGraphBySiteRealtimeLoader: false,
            };
        case DashboardOverviewTypes.FETCH_SIZE_TABLE_REALTIME:
            return {
                ...state,
                sizeTableRealtimeLoader: true,
            };
        case DashboardOverviewTypes.SET_SIZE_TABLE_REALTIME:
            return {
                ...state,
                sizeTableRealTime: action.payload,
                sizeTableRealtimeLoader: false,
            };
        case DashboardOverviewTypes.FETCH_NETWORK_TABLE_REALTIME:
            return {
                ...state,
                networkTableRealtimeLoader: true,
            };
        case DashboardOverviewTypes.SET_NETWORK_TABLE_REALTIME:
            return {
                ...state,
                networkTableRealtime: action.payload,
                networkTableRealtimeLoader: false,
            };
        case DashboardOverviewTypes.FETCH_POPULAR_PAGE_REALTIME:
            return {
                ...state,
                popularPageTableRealtimeLoader: true,
            };
        case DashboardOverviewTypes.SET_POPULAR_PAGE_REALTIME:
            return {
                ...state,
                popularPageTableRealtime: action.payload,
                popularPageTableRealtimeLoader: false,
            };

        default:
            return state;
    }
};

export default dashboardOverviewReducer;
