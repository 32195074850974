import React from 'react';
import CrowdFundingCharts from './components/dashboard/CrowdFundingCharts';
import CrowdFundingTables from './components/dashboard/CrowdFundingTables';
import StatsCrowdFunding from './components/dashboard/StatsCrowdFunding';
import CrowdFundingTopCards from './components/dashboard/topCardsCrowdFunding';
import { useSearchParams } from 'react-router-dom';
import TotalDonorsList from './components/dashboard/topCardsCrowdFunding/TotalDonorsList';

export const API_date_format = 'YYYY/MM/DD';

type Props = {
    siteId: any;
    startDate: any;
    endDate: any;
    compaison?: any;
    compare?: any;
    compare_start_date?: any;
    compare_end_date?: any;
    revenueType?: any;
};

const CrowdFundingDashboard: React.FC<Props> = ({ siteId, startDate, endDate, compaison, compare, compare_start_date, compare_end_date, revenueType }) => {
    const [searchParams] = useSearchParams();

    if (searchParams.get('key') === 'Total Donors') {
        console.log(searchParams.get('key'))
        return <>
            <TotalDonorsList
                startDate={startDate}
                endDate={endDate}
                revenueType={revenueType}
                compare={compaison}
                compare_start_date={compaison === false ? '' : compare_start_date}
                compare_end_date={compaison === false ? '' : compare_end_date}
                siteId={siteId != 0 ? siteId : null}
            />
        </>;
    }
    return (
        <div className="dashboard">
            <CrowdFundingTopCards startDate={startDate} endDate={endDate} revenueType={revenueType} comparison={compaison} compare={compare} compare_start_date={compare_start_date} compare_end_date={compare_end_date} siteId={siteId !== 0 ? siteId : null} />

            <StatsCrowdFunding startDate={startDate} endDate={endDate} revenueType={revenueType} siteId={siteId !== 0 ? siteId : null} />

            <CrowdFundingTables startDate={startDate} endDate={endDate} revenueType={revenueType} compare={compare} compare_start_date={compare_start_date} compare_end_date={compare_end_date} comparison={compaison} siteId={siteId !== 0 ? siteId : null} />

            <CrowdFundingCharts
                startDate={startDate}
                endDate={endDate}
                revenueType={revenueType}
                compare={compaison}
                compare_start_date={compaison === false ? '' : compare_start_date}
                compare_end_date={compaison === false ? '' : compare_end_date}
                siteId={siteId !== 0 ? siteId : null}
            />
        </div>
    );
};

export default CrowdFundingDashboard;
