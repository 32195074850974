import React from 'react'
type Props = {
     color: string;
}
const AdRecoverySvgFile: React.FC<Props> = ({ color }) => {
     return (
          <div className='mr-[10px]'>
               <svg width="21" height="21" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M25.4049 12C25.4049 18.6167 19.7065 24 12.7024 24C5.69837 24 0 18.6167 0 12C0 11.5855 0.355139 11.25 0.793902 11.25C1.23267 11.25 1.5878 11.5855 1.5878 12C1.5878 17.7897 6.57378 22.5 12.7024 22.5C18.8311 22.5 23.8171 17.7897 23.8171 12C23.8171 6.21025 18.8311 1.5 12.7024 1.5C9.74833 1.5 7.00592 2.58475 4.94284 4.5H7.93902C8.37779 4.5 8.73293 4.8355 8.73293 5.25C8.73293 5.6645 8.37779 6 7.93902 6H3.17561C2.73685 6 2.38171 5.6645 2.38171 5.25V0.75C2.38171 0.3355 2.73685 0 3.17561 0C3.61437 0 3.96951 0.3355 3.96951 0.75V3.3085C6.31179 1.1965 9.38948 0 12.7024 0C19.7065 0 25.4049 5.38325 25.4049 12ZM19.8476 9V15.75C19.8476 16.0627 19.6422 16.3425 19.3328 16.4525L12.9816 18.7025C12.8917 18.734 12.7969 18.75 12.7024 18.75C12.608 18.75 12.5132 18.734 12.4232 18.7025L6.07203 16.4525C5.76267 16.3425 5.55732 16.0627 5.55732 15.75V9C5.55732 8.68725 5.76267 8.4075 6.07203 8.2975L12.4232 6.0475C12.6032 5.9845 12.8017 5.9845 12.9814 6.0475L19.3326 8.2975C19.6422 8.4075 19.8476 8.68725 19.8476 9ZM8.61199 9L12.7024 10.4487L16.7929 9L12.7024 7.55125L8.61199 9ZM7.14512 15.23L11.9085 16.9175V11.77L7.14512 10.0825V15.23ZM18.2598 15.23V10.0825L13.4963 11.77V16.9175L18.2598 15.23Z" fill={color} />
               </svg>

          </div>
     )
}

export default AdRecoverySvgFile